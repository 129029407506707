import classNames from "classnames";
import { FC, createElement } from "react";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";

interface SummaryItem {
  title: string;
  value: string;
}

interface PQProductDetailsSummaryExpressionProps {
  question: QuestionExpressionModel;
}

const PQProductDetailsSummaryExpression: FC<
  PQProductDetailsSummaryExpressionProps
> = ({ question }) => {
  const summaryItems = question.expression as unknown as SummaryItem[];

  if (!summaryItems) return <></>;

  return (
    <div className="flex flex-col p-2.5 md:p-5 divide-indigo-100 rounded-lg shadow-sm md:flex-row md:divide-x bg-indigo-25 gap-1.5">
      {summaryItems.map((item, index) => (
        <div
          className={classNames(
            "md:px-5 md:block flex items-center justify-between gap-5",
            {
              "md:pl-0 md:pr-5": index === 0,
              "md:pl-5 md:pr-0": index === summaryItems.length - 1,
            }
          )}
        >
          <div className="text-xxs md:text-xs leading-[14px] md:leading-[18px] font-semibold text-indigo-600 capitalize">
            {item.title}
          </div>
          <div className="md:mt-2.5 truncate text-xs md:text-sm leading-[18px] md:leading-6 font-regular text-indigo-800">
            {item.value}
          </div>
        </div>
      ))}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-pq-product-details-summary-expression",
  function (props: any) {
    return createElement(PQProductDetailsSummaryExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "pq-product-details-summary-expression",
  "sv-pq-product-details-summary-expression"
);
