import { FilePond, FilePondProps } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { forwardRef } from "react";
import "./style.css";

export type FilePondUploadProps = FilePondProps;

export const FilePondUpload = forwardRef<FilePond, FilePondUploadProps>(
  (props, ref) => {
    return <FilePond ref={ref} {...props} />;
  }
);

FilePondUpload.displayName = "FilePondUpload";
