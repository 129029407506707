import { IconProps } from "react-feather";

export function Oil({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        d="M16.6198 3.14625C18.0903 3.5475 19 4.21763 19 5.06061L18.9969 19.6251C18.9966 19.8761 18.8658 20.1203 18.6325 20.2545C14.2717 22.766 9.80768 22.6093 5.41241 20.2817C5.16818 20.1639 5 19.914 5 19.6251V5.34324C5 4.08007 6.41967 3.39852 7.34467 3.14625C9.96808 2.42993 13.9964 2.42993 16.6198 3.14625ZM12 9.24099C12.1562 9.24099 12.7771 9.99001 13.4308 10.9822C15.2712 10.798 16.7695 10.323 17.5417 10.037V6.65651C17.3844 6.72502 17.2235 6.78576 17.0613 6.84104C14.2985 7.76655 10.2323 7.68791 7.60965 7.04451C7.2258 6.9202 6.9673 6.87815 6.45808 6.67182V9.81327C7.82351 10.5065 9.2258 10.8566 10.5632 10.9911C11.2191 9.99442 11.8432 9.24099 12 9.24099ZM14.2479 12.3388C14.7817 13.3186 15.1978 14.3207 15.1978 14.997C15.1978 15.14 15.1897 15.2778 15.1754 15.411C16.2066 15.1956 17.0322 14.9246 17.5417 14.7359V11.574C16.7527 11.8348 15.6065 12.1504 14.2479 12.3388ZM14.5494 16.9934C13.3257 18.534 10.686 18.5369 9.45679 17.0017C8.46924 16.8351 7.45938 16.554 6.45808 16.1185V19.181C10.1622 21.0336 13.8515 21.1847 17.5417 19.2002V16.2729C16.8116 16.514 15.776 16.8024 14.5494 16.9934ZM8.8217 15.3892C8.80898 15.2628 8.80197 15.1322 8.80197 14.997C8.80197 14.323 9.21568 13.3251 9.74669 12.3487C8.66753 12.1899 7.55749 11.8979 6.45808 11.4195V14.5119C7.24033 14.9093 8.03452 15.1932 8.8217 15.3892ZM12 11.1177C11.4189 11.9285 10.0589 14.099 10.0589 14.997C10.0589 17.5459 13.9411 17.5431 13.9411 14.997C13.9411 14.099 12.5811 11.9285 12 11.1177ZM7.67662 5.56048C10.0932 6.21192 13.8983 6.21295 16.2383 5.57423C17.9465 5.10784 17.9468 5.01363 16.2383 4.54724C13.8889 3.90618 10.0755 3.90618 7.72619 4.54724C6.19647 4.96484 5.96886 5.08941 7.67662 5.56048Z"
        fill={color}
      />
    </svg>
  );
}
