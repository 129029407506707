import { FC, createElement } from "react";
import { QuestionBooleanModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { Switch } from "../ui";

interface BooleanQuestionProps {
  question: QuestionBooleanModel;
}

const BooleanQuestion: FC<BooleanQuestionProps> = ({ question }) => {
  const {
    isReadOnly,
    labelTrue,
    labelFalse,
    defaultValue,
    ariaDescribedBy,
    ariaErrormessage,
    ariaLabel,
    ariaInvalid,
    ariaRequired,
    ariaLabelledBy,
  } = question;
  const onCheckedChange = (checked: boolean) => {
    question.value = checked;
  };

  return (
    <Switch
      trueLabel={labelTrue}
      falseLabel={labelFalse}
      checked={question.value}
      onCheckedChange={onCheckedChange}
      disabled={isReadOnly}
      required={question.isRequired}
      defaultChecked={defaultValue}
      aria-describedby={ariaDescribedBy}
      aria-errormessage={ariaErrormessage}
      aria-label={ariaLabel}
      aria-invalid={ariaInvalid}
      aria-required={ariaRequired}
      aria-labelledby={ariaLabelledBy}
    />
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-boolean-question",
  function (props: any) {
    return createElement(BooleanQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "boolean",
  "boolean-question",
  "sv-boolean-question"
);
