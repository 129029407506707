import {
  Root,
  Item,
  Indicator,
  RadioGroupProps as RootProps,
} from "@radix-ui/react-radio-group";
import { FC } from "react";

interface RadioGroupItem {
  value: string;
  title: string;
}

type RadioGroupProps = {
  items: RadioGroupItem[];
} & RootProps;

export const RadioGroup: FC<RadioGroupProps> = ({ items, ...props }) => {
  return (
    <Root className="flex flex-col gap-[15px]" {...props}>
      {items.map((item) => (
        <div className="flex items-center" key={item.value}>
          <Item
            className="bg-white w-[16px] h-[16px] rounded-full border border-gray-300 focus:ring-4 focus:ring-indigo-100 data-[disabled]:bg-gray-100 data-[disabled]:border-gray-200 data-[disabled]:data-[state=checked]:border-gray-400 data-[state=checked]:bg-indigo-50 data-[state=checked]:border-indigo-600"
            value={item.value}
            id={item.value}
          >
            <Indicator className="flex items-center justify-center w-full h-full relative  after:content-[''] after:block after:w-[5px] after:h-[5px] after:rounded-[50%] after:bg-indigo-600 data-[disabled]:after:bg-gray-400" />
          </Item>
          <label
            className={`pl-2 text-xs md:text-sm font-medium leading-[16px] md:leading-6  ${
              props.disabled ? "text-gray-300" : "text-gray-700"
            }`}
            htmlFor={item.value}
          >
            {item.title}
          </label>
        </div>
      ))}
    </Root>
  );
};
