import { useAtom, useAtomValue } from "jotai";
import { isMobileDrawerOpenAtom, sidebarContentAtom } from "../../jotai-atoms";
import { AppIcon, LanguageSelect, MenuSection } from "../atoms";
import { FC, useCallback } from "react";
import { useBreakpoint } from "../../hooks";
import {
  NavigationMenu,
  NavigationMenuList,
} from "@radix-ui/react-navigation-menu";
import { NavBarItem } from "../molecules";
import { MenuItem } from "../../types";

interface MenuDrawerTemplateProps {
  items: MenuItem[];
  disabled?: boolean;
  activePageName: string;
  onItemClick: (name: string) => void;
}

export const MenuDrawerTemplate: FC<MenuDrawerTemplateProps> = ({
  items,
  disabled,
  activePageName,
  onItemClick,
}) => {
  const sidebarContent = useAtomValue(sidebarContentAtom);
  const { isAboveMd } = useBreakpoint("md");
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useAtom(
    isMobileDrawerOpenAtom
  );

  const onMenuItemClick = useCallback(
    ({ pages }: MenuItem) => {
      if (pages.length) onItemClick(pages[0].name);
      setIsMobileDrawerOpen(false);
    },
    [onItemClick, setIsMobileDrawerOpen]
  );

  if (isAboveMd) return <></>;

  return (
    <div
      className={`${
        isMobileDrawerOpen ? "block" : "hidden"
      } bg-white z-50 w-screen h-screen fixed py-[15px] px-6`}
    >
      <div className="flex items-center justify-between mb-[34px]">
        <LanguageSelect />
        <AppIcon
          name="X"
          onClick={() => setIsMobileDrawerOpen(false)}
          size={20}
          color="var(--indigo-500)"
        />
      </div>
      {!disabled && (
        <div className="h-full overflow-auto">
          <MenuSection>
            <NavigationMenu orientation="vertical">
              <NavigationMenuList className="flex flex-col gap-[15px]">
                {sidebarContent?.navigationItems.map((item) => (
                  <NavBarItem
                    disabled={disabled}
                    item={item}
                    key={item.id}
                    active={
                      item.pages.find(
                        (page) => page.name === activePageName
                      ) !== undefined
                    }
                    onClick={() => onMenuItemClick(item)}
                  />
                ))}
              </NavigationMenuList>
            </NavigationMenu>
          </MenuSection>
        </div>
      )}
    </div>
  );
};
