import { FC, createElement } from "react";
import { ExplainerDialog } from "../ui";
import { ReactQuestionFactory } from "survey-react-ui";
import {
  PageModel,
  QuestionExpressionModel,
  RendererFactory,
} from "survey-core";
import { AppIcon } from "../atoms";
import { useBreakpoint } from "../../hooks";

interface PageTitleExpressionProps {
  question: QuestionExpressionModel;
}

export const PageTitleExpression: FC<PageTitleExpressionProps> = ({
  question,
}) => {
  const { title } = question;
  const { isAboveMd } = useBreakpoint("md");
  const icon = question.getPropertyValue("icon", "");
  const withHelper: boolean = (question.page as PageModel).getPropertyValue(
    "withHelper",
    false
  );

  return (
    <div className="flex justify-between gap-5">
      <div className="flex items-center md:gap-[15px] gap-2">
        {icon && (
          <div className="grid w-[30px] h-[30px] shrink-0 md:w-10 md:h-10 border border-gray-100 rounded-full place-items-center">
            <AppIcon
              size={isAboveMd ? 24 : 18}
              color="var(--gray-400)"
              name={icon}
            />
          </div>
        )}
        <div className="text-sm font-medium text-gray-800 md:text-lg text-wrap">
          {title}
        </div>
      </div>
      {withHelper && <ExplainerDialog />}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-page-title-expression",
  function (props: any) {
    return createElement(PageTitleExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "page-title-expression",
  "sv-page-title-expression"
);
