import classNames from "classnames";
import { Watermark } from "../atoms";
import { FC } from "react";
import { SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";
import { Footer, Sidebar, SidebarProps } from "../organisms";
import { isDisplayMode } from "../../utils";

interface SurveyPageTemlpateProps {
  menuProps: SidebarProps;
  isNewQuestionnaire: boolean;
  showSideBar: boolean;
  survey: SurveyModel;
  showNavButtons: boolean;
}
export const SurveyPageTemlpate: FC<SurveyPageTemlpateProps> = ({
  isNewQuestionnaire,
  survey,
  showSideBar,
  showNavButtons,
  menuProps,
}) => {
  return (
    <div
      className={`min-h-screen ${
        isNewQuestionnaire ? "md:grid md:place-items-center" : ""
      }`}
    >
      <div
        className={`${
          isNewQuestionnaire
            ? "py-[15px] md:py-0 xl:px-[298px] md:px-[100px] px-[7px] w-full"
            : "py-[55px] md:py-[94px]"
        }`}
      >
        {showSideBar && <Sidebar {...menuProps} />}
        <div
          className={classNames("flex flex-col gap-2.5", {
            "md:pl-[267px]":
              survey.getPropertyValue("navigationMenu") === "sidebar" &&
              showSideBar,
          })}
        >
          <Survey className="rounded-lg" model={survey} />
          {survey.isShowStartingPage && (
            <div className="grid md:hidden place-items-center">
              <Watermark />
            </div>
          )}
        </div>
        {!isNewQuestionnaire && !isDisplayMode(survey) && (
          <Footer survey={survey} showNavMenu={showNavButtons} />
        )}
      </div>
    </div>
  );
};
