import { IconProps } from "react-feather";

export function GarmentEmbroidery({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={size} height={size} fill="white" />
      <path
        d="M13.5204 13.0782L18.5344 17.9431L17.9065 18.5524L12.81 13.6075L4.81313 19.8991C4.71576 19.9713 4.59466 20.0068 4.47235 19.9989C4.35004 19.9911 4.23484 19.9404 4.14816 19.8562C4.06148 19.7721 4.00923 19.6604 4.00111 19.5417C3.99299 19.423 4.02957 19.3055 4.10403 19.2111L10.5898 11.4534L7.59591 8.54858C6.84448 7.81936 6.84448 6.63711 7.59591 5.90788C8.34749 5.17866 9.56597 5.17866 10.3176 5.90788L12.9548 8.46685L14.2282 6.85859L14.0036 6.6407C13.2521 5.91147 13.2521 4.72923 14.0036 4L14.6316 4.60944C14.5354 4.7028 14.4591 4.81364 14.4069 4.93564C14.3549 5.05763 14.3281 5.18838 14.3281 5.32042C14.3281 5.45246 14.3549 5.58321 14.4069 5.70521C14.4591 5.8272 14.5354 5.93804 14.6316 6.03141L14.7763 6.17159C15.623 5.2058 17.1108 5.04464 18.1586 5.82572C18.2436 5.88906 18.3244 5.95786 18.4001 6.03141C19.3637 6.96631 19.3637 8.48208 18.4001 9.41713C18.3536 9.46223 18.3051 9.50561 18.2551 9.54698L19.4364 10.693C20.1879 11.4223 20.1879 12.6045 19.4364 13.3337C18.6847 14.0629 17.4662 14.0629 16.7146 13.3337L15.1785 11.8429L13.5204 13.0782ZM11.1356 10.7643L12.409 9.15601L9.68959 6.51731C9.49524 6.32875 9.23165 6.22282 8.95681 6.22282C8.68196 6.22282 8.41837 6.32875 8.22403 6.51731C8.02968 6.70589 7.9205 6.96163 7.9205 7.2283C7.9205 7.49497 8.02968 7.75072 8.22403 7.93929L11.1356 10.7643ZM12.887 12.4634L16.9133 9.46381L16.2019 8.77336L15.7831 9.17956L15.1551 8.57027L16.6206 7.1483L17.2486 7.75774L16.8299 8.16393L17.6239 8.93438C17.676 8.89516 17.7254 8.85279 17.7719 8.80755C18.3888 8.20918 18.3888 7.23908 17.7719 6.64056C17.7234 6.59349 17.6719 6.54954 17.6175 6.50899C16.9256 5.9932 15.9337 6.11931 15.4021 6.79051L11.7691 11.3789L12.887 12.4634ZM12.1764 12.9929L11.2232 12.0679L6.58512 17.4924L12.1764 12.9929ZM15.8891 11.3136L17.3429 12.7243C17.5372 12.9129 17.8009 13.0188 18.0756 13.0188C18.3505 13.0188 18.6141 12.9129 18.8084 12.7243C19.0028 12.5357 19.112 12.28 19.112 12.0133C19.112 11.7466 19.0028 11.4909 18.8084 11.3023L17.5469 10.0786L15.8891 11.3136Z"
        fill={color}
      />
    </svg>
  );
}
