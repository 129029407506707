import { IconProps } from "react-feather";

export function Gas({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        d="M5.6087 13.1765C5.27252 13.1765 5 12.9131 5 12.5882C5 12.2634 5.27252 12 5.6087 12V9.64706C5.6087 8.11033 6.62498 6.803 8.04348 6.31849V2.58824C8.04348 2.26336 8.316 2 8.65217 2H15.3478C15.684 2 15.9565 2.26336 15.9565 2.58824V6.31849C17.375 6.803 18.3913 8.11033 18.3913 9.64706V12C18.7275 12 19 12.2634 19 12.5882C19 12.9131 18.7275 13.1765 18.3913 13.1765V15.5294C18.3913 17.0661 17.375 18.3735 15.9565 18.858V21.4118C15.9565 21.7366 15.684 22 15.3478 22H8.65217C8.316 22 8.04348 21.7366 8.04348 21.4118V18.858C6.62498 18.3735 5.6087 17.0661 5.6087 15.5294V13.1765ZM9.26087 4.05882H10.4783C10.8144 4.05882 11.087 4.32219 11.087 4.64706C11.087 4.97193 10.8144 5.23529 10.4783 5.23529H9.26087V6.11765H14.7391V5.23529H13.5217C13.1856 5.23529 12.913 4.97193 12.913 4.64706C12.913 4.32219 13.1856 4.05882 13.5217 4.05882H14.7391V3.17647H9.26087V4.05882ZM9.26087 19.0588V20.8235H14.7391V19.0588H9.26087ZM17.1739 12V9.64706C17.1739 8.34756 16.0838 7.29412 14.7391 7.29412H9.26087C7.91618 7.29412 6.82609 8.34756 6.82609 9.64706V12H17.1739ZM6.82609 13.1765V15.5294C6.82609 16.8289 7.91618 17.8824 9.26087 17.8824H14.7391C16.0838 17.8824 17.1739 16.8289 17.1739 15.5294V13.1765H6.82609Z"
        fill={color}
      />
    </svg>
  );
}
