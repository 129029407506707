import { ReactQuestionFactory } from "survey-react-ui";
import {
  QuestionExpressionModel,
  RendererFactory,
  SurveyModel,
} from "survey-core";
import { createElement, FC, useCallback } from "react";
import { StartButton, Watermark } from "../atoms";

interface StartPageFooterProps {
  question: QuestionExpressionModel;
}

export const StartPageFooter: FC<StartPageFooterProps> = ({ question }) => {
  const survey = question.survey as SurveyModel;

  const onClickStart = useCallback(() => {
    survey.start();
  }, [survey]);
  return (
    <div className="flex items-center justify-between w-full">
      <div className="hidden md:block">
        <Watermark />
      </div>
      <StartButton onClick={onClickStart} label="button-start" />
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-start-page-footer-expression",
  function (props: any) {
    return createElement(StartPageFooter, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "start-page-footer-expression",
  "sv-start-page-footer-expression"
);
