export function LogoMini(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="18"
      viewBox="0 0 86 18"
      fill="none"
    >
      <path
        d="M5.47188 12.5182L7.22572 0.613403H10.8031V17.381H8.36726V5.36284L6.58907 17.381H4.15318L2.24434 5.52837V17.381H0V0.620206H3.57743L5.47188 12.5182Z"
        fill="#1A2023"
      />
      <path
        d="M20.9972 17.381H18.4019L17.959 14.3402H14.8001L14.3572 17.381H11.9944L14.6152 0.620239H18.3797L20.9972 17.381ZM15.1289 12.0647H17.6047L16.3646 3.58849L15.1289 12.0647Z"
        fill="#1A2023"
      />
      <path
        d="M22.1897 0.620239H26.2576C28.8308 0.620239 30.093 2.08056 30.093 4.76196V13.2382C30.093 15.9196 28.8308 17.381 26.2576 17.381H22.1897V0.620239ZM24.7618 3.01366V14.9865H26.2111C27.0294 14.9865 27.521 14.5556 27.521 13.3583V4.64291C27.521 3.44564 27.0294 3.01366 26.2111 3.01366H24.7618Z"
        fill="#1A2023"
      />
      <path
        d="M34.3014 7.68373H37.8324V10.0772H34.3014V14.9865H38.7436V17.381H31.7294V0.620239H38.7436V3.01366H34.3014V7.68373Z"
        fill="#1A2023"
      />
      <path
        d="M47.1673 4.78571C47.1673 9.45465 40.9469 11.0113 40.8561 16.7823V16.8775H47.0676V17.381H40.3335V17.0215C40.3335 10.7483 46.6447 9.31179 46.6447 4.80952C46.6447 2.70295 45.9903 0.978458 43.7216 0.978458C41.5238 0.978458 40.8229 2.79819 40.8229 4.80952V5.71655H40.3313V4.83333C40.3313 2.51134 41.2171 0.5 43.7449 0.5C46.3203 0.5 47.1673 2.41497 47.1673 4.78571Z"
        fill="#1A2023"
      />
      <path
        d="M54.0835 9.04768V9.55108H49.4996V17.381H48.9858V0.620239H54.9947V1.12251H49.4996V9.04768H54.0835Z"
        fill="#1A2023"
      />
      <path
        d="M56.7712 17.381V0.620239H57.2861V16.8776H62.779V17.381H56.7712Z"
        fill="#1A2023"
      />
      <path
        d="M64.5345 4.7381C64.5345 2.39116 65.5167 0.5 67.9957 0.5C70.5202 0.5 71.5023 2.39116 71.5023 4.7381V13.2619C71.5023 15.6088 70.5202 17.5 67.9957 17.5C65.5167 17.5 64.5345 15.6088 64.5345 13.2619V4.7381ZM65.0494 13.2857C65.0494 15.3458 65.8245 17.0272 67.9957 17.0272C70.1935 17.0272 70.9852 15.3515 70.9852 13.2914V4.71429C70.9852 2.65533 70.1902 0.978458 67.9957 0.978458C65.82 0.978458 65.0494 2.65533 65.0494 4.71995V13.2857Z"
        fill="#1A2023"
      />
      <path
        d="M76.9736 17.381H76.2251L73.2788 0.620239H73.7937L76.6459 16.6622L79.4759 0.715477H80.107L82.7721 16.5669L85.5313 0.620239H85.9997L83.0999 17.381H82.4211L79.7793 1.8606L76.9736 17.381Z"
        fill="#1A2023"
      />
    </svg>
  );
}
