import { FC, ReactNode, useCallback, useMemo } from "react";
import { Dropdown } from "../ui/dropdown";
import { ReactComponent as USFlag } from "../../assets/icons/US.svg";
import { ReactComponent as CNFlag } from "../../assets/icons/CN.svg";
import { ReactComponent as ESFlag } from "../../assets/icons/ES.svg";
import { ReactComponent as VNFlag } from "../../assets/icons/VN.svg";
import { ReactComponent as ESLATFlag } from "../../assets/icons/ES-LAT.svg";
import { ReactComponent as TRFlag } from "../../assets/icons/TR.svg";
import { T, useTolgee } from "@tolgee/react";

interface Language {
  icon: ReactNode;
  value: string;
  keyName: string;
}

interface LanguageSelectProps {
  className?: string;
  onLanguageChange?: (val: string) => void;
}

export const LanguageSelect: FC<LanguageSelectProps> = ({
  onLanguageChange,
  className,
}) => {
  const tolgee = useTolgee(["language"]);

  const renderdItems = useMemo(() => {
    const languages = tolgee
      .getInitialOptions()
      .availableLanguages?.map((lang) => ({
        icon: getCountryIcon(lang),
        value: lang,
        keyName: `lang-${lang}`,
      }));
    return languages?.map((lang) => ({
      value: lang.value,
      title: <LanguageItemChildren lang={lang} />,
    }));
  }, [tolgee]);

  const onValueChange = useCallback(
    (val: string) => {
      tolgee.changeLanguage(val);
      if (onLanguageChange) onLanguageChange(val);
    },
    [onLanguageChange, tolgee]
  );

  return (
    <Dropdown
      className={className}
      items={renderdItems}
      name="language-select"
      value={tolgee.getLanguage()}
      onValueChange={onValueChange}
    />
  );
};

const LanguageItemChildren = ({ lang }: { lang: Language }) => {
  return (
    <div className="flex items-center gap-2">
      {lang.icon}
      <span className="text-sm font-medium text-gray-500">
        <T keyName={lang.keyName} />
      </span>
    </div>
  );
};

function getCountryIcon(lang: string) {
  switch (lang) {
    case "en":
      return <USFlag className="w-5 h-5" />;
    case "zh-Hans-CN":
      return <CNFlag className="w-5 h-5" />;
    case "es":
      return <ESFlag className="w-5 h-5" />;
    case "es-lat":
      return <ESLATFlag className="w-5 h-5" />;
    case "vi-VN":
      return <VNFlag className="w-5 h-5" />;
    case "tr-TR":
      return <TRFlag className="w-5 h-5" />;
  }
}
