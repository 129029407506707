import { createElement } from "react";
import { QuestionPanelDynamicModel } from "survey-core";
import {
  ReactElementFactory,
  SurveyQuestionPanelDynamicAddButton,
} from "survey-react-ui";
import * as Icon from "../icons";

class DynamicPanelAddButton extends SurveyQuestionPanelDynamicAddButton {
  protected get panel(): QuestionPanelDynamicModel {
    return this.question as QuestionPanelDynamicModel;
  }

  protected renderElement(): JSX.Element {
    return (
      <button
        className="flex gap-[7px] items-center px-[21px] py-[7px] w-full"
        type="button"
        disabled={this.panel.isInputReadOnly}
        onClick={this.handleClick}
      >
        <Icon.PlusCircle color="var(--indigo-500)" />
        <span className="text-sm font-semibold text-left text-indigo-500 text-wrap">
          {this.question.panelAddText}
        </span>
      </button>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "sv-paneldynamic-add-btn",
  (props) => {
    return createElement(DynamicPanelAddButton, props);
  }
);
