import { atom } from "jotai";
import { Question } from "survey-core";
import { QuestionExpressionModel } from "survey-core";
import { MenuItem } from "./types";

export const isNewQuestionnaireAtom = atom(false);
export const questionnaireNameAtom = atom("");
export const isMobileDrawerOpenAtom = atom(false);
export const uploadedFilesSetAtom = atom(new Set<string>());
export const sidebarContentAtom = atom<{ navigationItems: MenuItem[] }>({
  navigationItems: [],
});

//global language
export const languageQuestionAtom = atom<Question | undefined>(undefined);
export const explainerContentQuestionAtom = atom<
  QuestionExpressionModel | undefined
>(undefined);
