import { ReactQuestionFactory } from "survey-react-ui";
import {
  QuestionExpressionModel,
  RendererFactory,
  SurveyModel,
} from "survey-core";
import { createElement, FC, useMemo } from "react";
import { useTolgee } from "@tolgee/react";
import { ExplainerContent, ExplainerFooter } from "../ui";
import { ExplainerExpression } from "../../types";
import { isExplainerImage } from "../../utils";

interface ExplainerPageContentProps {
  question: QuestionExpressionModel;
}

const ExplainerPageContent: FC<ExplainerPageContentProps> = ({ question }) => {
  const tolgee = useTolgee();
  const { expression, title, survey } = question;
  const data = expression as ExplainerExpression;

  const explainerImage = useMemo(() => {
    if (data.explainerImage) {
      if (typeof data.explainerImage === "string") {
        return {
          src: `images/explainers/${tolgee.getLanguage()}/${
            data.explainerImage
          }`,
        };
      } else if (isExplainerImage(data.explainerImage)) {
        return {
          src: `images/explainers/${tolgee.getLanguage()}/${
            data.explainerImage.src
          }`,
          size: data.explainerImage.size,
        };
      }
    }
  }, [data.explainerImage, tolgee]);

  const onBeginQuestionnaireClick = () => (survey as SurveyModel).nextPage();

  return (
    <div className="flex flex-col gap-5 md:gap-[30px]">
      <ExplainerContent
        data={{
          title,
          points: data.points,
          explainerImage,
        }}
      />
      <ExplainerFooter onClickNext={onBeginQuestionnaireClick} />
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-explainer-page-content-expression",
  function (props: any) {
    return createElement(ExplainerPageContent, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "explainer-page-content-expression",
  "sv-explainer-page-content-expression"
);
