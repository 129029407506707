import { T } from "@tolgee/react";
import { Frown } from "../icons";
import { TopBar } from "../organisms";
import { FC } from "react";

interface ErrorPageTemplateProps {
  errorMessage: string;
}

export const ErrorPageTemplate: FC<ErrorPageTemplateProps> = ({
  errorMessage,
}) => {
  return (
    <>
      <TopBar />
      <div
        id="error-page"
        className="flex flex-col items-center justify-center h-screen px-5"
      >
        <div className="flex flex-col items-center">
          <Frown className="mt-5" color="var(--indigo-500)" size={34} />
          <h1 className="mt-2.5 text-base md:text-display-xs font-medium text-gray-800">
            <T keyName="page-not-found-error-title" />
          </h1>
          <p className="text-sm font-medium text-center text-gray-700 whitespace-pre-line md:text-lg">
            <T keyName={errorMessage} />
          </p>
        </div>
      </div>
    </>
  );
};
