import { z } from "zod";

export const appConfigSchema = z.object({
  surveyApi: z.string().url(),
  tolgeeApiUrl: z.string().url().optional(),
  tolgeeApiKey: z.string().optional(),
  availableLanguages: z.string().array().min(1).default(["en"]),
  paginationConfig: z.object({
    maxItemsPerPage: z.number().default(30),
    itemsPerPage: z.number().default(5),
    selectedPage: z.number().default(0),
    displayInterval: z.number().default(5),
    pageCounter: z.number().default(0),
  }),
  maxFileSize: z.number().default(20971520),
});
