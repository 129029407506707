import { ReactQuestionFactory } from "survey-react-ui";
import {
  QuestionExpressionModel,
  RendererFactory,
  SurveyModel,
} from "survey-core";
import { createElement, FC } from "react";
import { ExplainerFooter } from "../../ui";

interface ExplainerPageFooterProps {
  question: QuestionExpressionModel;
}

const ExplainerPageFooter: FC<ExplainerPageFooterProps> = ({ question }) => {
  const { survey } = question;

  const onBeginQuestionnaireClick = () => (survey as SurveyModel).nextPage();

  return <ExplainerFooter onClickNext={onBeginQuestionnaireClick} />;
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-explainer-page-footer-expression",
  function (props: any) {
    return createElement(ExplainerPageFooter, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "explainer-page-footer-expression",
  "sv-explainer-page-footer-expression"
);
