import {
  NavigationMenu,
  NavigationMenuList,
} from "@radix-ui/react-navigation-menu";
import { NavBarItem } from "../molecules";
import { FC, useCallback } from "react";
import { SidebarContainer } from "../atoms";
import { useAtomValue } from "jotai";
import { sidebarContentAtom } from "../../jotai-atoms";
import { MenuItem } from "../../types";

export interface SidebarProps {
  disabled?: boolean;
  onItemClick: (name: string) => void;
  activePageName: string;
}

export const Sidebar: FC<SidebarProps> = ({
  disabled,
  onItemClick,
  activePageName,
}) => {
  const sidebarContent = useAtomValue(sidebarContentAtom);

  const onClick = useCallback(
    ({ pages }: MenuItem) => {
      if (pages.length > 0) {
        onItemClick(pages[0].name);
      }
    },
    [onItemClick]
  );

  return (
    <SidebarContainer>
      <NavigationMenu orientation="vertical">
        <NavigationMenuList className="flex flex-col gap-[15px]">
          {sidebarContent?.navigationItems.map((item) => (
            <NavBarItem
              disabled={disabled}
              item={item}
              key={item.id}
              active={
                item.pages.find((page) => page.name === activePageName) !==
                undefined
              }
              onClick={() => onClick(item)}
            />
          ))}
        </NavigationMenuList>
      </NavigationMenu>
    </SidebarContainer>
  );
};
