import { Root, Thumb, type SwitchProps as Props } from "@radix-ui/react-switch";
import { FC, useCallback } from "react";

type SwitchProps = {
  trueLabel?: string;
  falseLabel?: string;
} & Props;

export const Switch: FC<SwitchProps> = ({
  trueLabel,
  falseLabel,
  onCheckedChange,
  ...props
}) => {
  //override default Root element onCheckedChange function to make sure that if the switch has no value and
  //a user is clicking on the false thumnb the value will be false and not true
  const handleOnThumcClick = useCallback(
    (checked: boolean) => {
      if (onCheckedChange) onCheckedChange(checked);
    },
    [onCheckedChange]
  );

  return (
    <Root
      {...props}
      className="flex items-center justify-between bg-gray-100 rounded-lg h-[36px] w-[88px] outline-none cursor-default"
    >
      {props.checked !== undefined && (
        <Thumb className="absolute ml-[5px] w-[37px] h-[28px] bg-white rounded-lg text-xs leading-[16px] md:leading-6 grid place-items-center font-semibold text-gray-700 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[39px] data-[disabled]:text-gray-400">
          {props.checked ? trueLabel : falseLabel}
        </Thumb>
      )}
      <Thumb
        onClick={() => handleOnThumcClick(false)}
        className="block ml-[5px] w-[37px] h-[28px] grid text-xs leading-[16px] md:leading-6 font-semibold text-gray-700 place-items-center data-[disabled]:text-gray-400"
      >
        {falseLabel}
      </Thumb>
      <Thumb
        onClick={() => handleOnThumcClick(true)}
        className="block mr-[5px] w-[37px] h-[28px] grid text-xs leading-[16px] md:leading-6 font-semibold text-gray-700 place-items-center data-[disabled]:text-gray-400"
      >
        {trueLabel}
      </Thumb>
    </Root>
  );
};
