import { CheckCircle } from "../icons";

export const CompletePageTemplate = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen px-5">
      <div className="flex gap-[11px] items-center">
        <CheckCircle size={24} color="var(--indigo-500)" />
        <span className="text-base font-medium leading-6 text-indigo-500 whitespace-normal md:text-lg md:leading-7 ">
          %-%tolgee:submit-page-header%-%
        </span>
      </div>
      <span className="mt-5 text-base font-medium leading-6 text-center text-gray-800 whitespace-normal md:leading-8 md:text-display-xs text-wrap">
        %-%tolgee:submit-page-title%-%
      </span>
      <span className="mt-4 leading-5 text-center text-gray-500 whitespace-normal tex-sm md:text-lg md:leading-7 font-regular text-wrap">
        %-%tolgee:submit-page-description%-%
      </span>
    </div>
  );
};
