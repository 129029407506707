export function validateProductHasMainFabric(
  params: { [x: string]: string }[][]
) {
  if (params[0]) {
    const values = params[0].map((item) => Object.values(item)[0]);
    return values.some((val: string) => val === "POSITIONING#MAIN_FABRIC");
  }

  return false;
}
