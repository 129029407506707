import { FC, PropsWithChildren } from "react";
import { ApiServiceContext } from "../../contexts";
import { ApiService } from "../../services";

export const ApiServiceProvider: FC<
  PropsWithChildren<{ apiService?: ApiService }>
> = ({ children, apiService = new ApiService({}) }) => {
  return (
    <ApiServiceContext.Provider value={{ apiService }}>
      {children}
    </ApiServiceContext.Provider>
  );
};
