import { FC, createElement } from "react";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";

interface IntroPageTitleProps {
  question: QuestionExpressionModel;
}

const IntroPageTitle: FC<IntroPageTitleProps> = ({ question }) => {
  const { title } = question;

  return (
    <div className="font-medium leading-6 text-gray-800 md:text-display-xs text-md text-wrap">
      {title}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-intro-page-title",
  function (props: any) {
    return createElement(IntroPageTitle, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "intro-page-title",
  "sv-intro-page-title"
);
