import { T } from "@tolgee/react";
import "./index.scss";

interface LoadingProps {
  message?: string;
}

export function Loading({ message }: LoadingProps) {
  return (
    <div className="grid w-full h-screen place-items-center">
      <div className="flex flex-col items-center gap-6">
        <div className="dot-pulse"></div>
        <span className="text-lg font-medium text-gray-800">
          {message ?? <T keyName="loading-message" />}
        </span>
      </div>
    </div>
  );
}
