import { T } from "@tolgee/react";
import classNames from "classnames";
import { ButtonHTMLAttributes, FC } from "react";
import { ChevronLeft } from "../icons";

export const PrevButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => {
  return (
    <button
      className={classNames(
        "bg-white md:py-2.5 md:px-4 p-2 rounded-lg border border-gray-300",
        className
      )}
      {...props}
    >
      <div className="flex items-center gap-2">
        <ChevronLeft size={20} color="var(--gray-700)" />
        <span className="hidden text-sm font-semibold leading-5 text-gray-700 md:inline">
          <T keyName="button-previous" />
        </span>
      </div>
    </button>
  );
};
