import { ChangeEventHandler, FC, useCallback, useMemo, useRef } from "react";
import { FileQuestion } from "../../../types";
import { Loader, Upload, X } from "react-feather";
import classNames from "classnames";

interface FileUploadProps {
  name: string;
  onUploadFiles: ChangeEventHandler<HTMLInputElement>;
  onRemoveFile: (fileId: string) => void;
  onDownloadFile: (fileId: string) => void;
  allowMultiple?: boolean;
  acceptedTypes?: string;
  disabled?: boolean;
  files: FileQuestion[];
  isUploading?: boolean;
  isRemoving?: boolean;
  error?: string;
  onCleanError?: () => void;
}

export const FileUpload: FC<FileUploadProps> = ({
  name,
  acceptedTypes,
  onUploadFiles,
  allowMultiple,
  disabled,
  files,
  isUploading,
  onRemoveFile,
  onDownloadFile,
  isRemoving,
  error,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const canUploadMore = useMemo(() => {
    return files.length === 0 || (files.length > 0 && allowMultiple);
  }, [allowMultiple, files.length]);

  const handleUploadClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | SVGElement>) => {
      if (fileInputRef.current) {
        e.preventDefault();
        fileInputRef.current.click();
      }
    },
    []
  );

  const handleRemoveFileClick = useCallback(
    (e: React.MouseEvent<SVGElement>, fileId: string) => {
      e.preventDefault();
      if (fileInputRef.current) fileInputRef.current.value = "";
      onRemoveFile(fileId);
    },
    [onRemoveFile]
  );

  return (
    <>
      <input
        ref={fileInputRef}
        name={name}
        type="file"
        accept={acceptedTypes}
        hidden
        onChange={onUploadFiles}
        multiple={allowMultiple}
        disabled={disabled}
      />
      <div
        className={classNames(
          "border border-indigo-100 border-dashed rounded-lg bg-indigo-50 p-1.5 min-h-[40px] grid items-center w-fit",
          {
            "!border-gray-200 !bg-gray-50": disabled,
          }
        )}
      >
        <div
          className={classNames("flex items-center", {
            "gap-2.5": files.length > 0,
          })}
        >
          {!disabled && (
            <button
              onClick={handleUploadClick}
              disabled={disabled || !canUploadMore}
              className="appearance-none min-w-[160px] py-2 px-[14px] text-xs md:text-sm leading-[16px] md:leading-6 font-semibold text-indigo-700 bg-white rounded-lg border border-indigo-200 cursor-pointer outline-none disabled:bg-gray-50 disabled:text-gray-40 disabled:cursor-not-allowed"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-0.5">
                  {(isUploading || isRemoving) && (
                    <Loader
                      className="animate-spin"
                      size={16}
                      color="var(--indigo-300)"
                    />
                  )}
                  {!isUploading && !isRemoving && (
                    <span className={`${canUploadMore ? "" : "text-gray-400"}`}>
                      %-%tolgee:upload-file-button%-%
                    </span>
                  )}
                  {isUploading && (
                    <span>%-%tolgee:uploading-file-in-progress-message%-%</span>
                  )}
                  {isRemoving && (
                    <span>%-%tolgee:removing-file-in-progress-message%-%</span>
                  )}
                </div>
                <Upload
                  color={`${
                    canUploadMore ? "var(--indigo-700)" : "var(--gray-400)"
                  }`}
                  size={16}
                />
              </div>
            </button>
          )}
          {files.length > 0 && (
            <div
              className={classNames(
                "grid justify-center w-full grid-flow-row-dense",
                {
                  "grid-cols-1": files.length === 1,
                  "grid-cols-2": files.length === 2,
                  "grid-cols-3": files.length >= 3,
                }
              )}
            >
              {files.map((file) => (
                <div className="flex items-center col-span-1" key={file.id}>
                  {!disabled && (
                    <X
                      onClick={(e) => handleRemoveFileClick(e, file.id)}
                      size={16}
                      color="var(--indigo-500)"
                      className="cursor-pointer shrink-0"
                    />
                  )}
                  <div
                    onClick={() => onDownloadFile(file.id)}
                    className={classNames(
                      "text-xs md:text-sm font-semibold text-indigo-700 truncate hover:underline hover:cursor-pointer leading-[16px] md:leading-6",
                      {
                        "!text-gray-400": disabled,
                      }
                    )}
                  >
                    {file.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {error && (
          <div className="flex items-center w-full">
            <span className="truncate text-xs md:text-xs leading-[16px] md:leading-6 text-error-500">
              {error}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
