import { T } from "@tolgee/react";
import { Logo, LogoWhite } from "../icons";
import { useBreakpoint } from "../../hooks";

export const Watermark = () => {
  const { isAboveMd } = useBreakpoint("md");

  return (
    <div className="flex items-center gap-3">
      <span className="text-sm font-medium leading-5 text-white md:text-gray-600">
        <T keyName="presented-by" />
      </span>
      {isAboveMd ? <Logo /> : <LogoWhite />}
    </div>
  );
};
