/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {
      backgroundImage: {
        "side-bar-background": "url('../public/images/side-bar-background.png')",
        "dark-blue-gradient": "url('../public/images/dark-blue-gradient.jpeg')",
      },
      boxShadow: {
        'xs': '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        'sm': '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);',
        'md': '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);'
      },
      colors: {
        'gray': {
          50: 'var(--gray-50)',
          100: 'var(--gray-100)',
          200: 'var(--gray-200)',
          300: 'var(--gray-300)',
          400: 'var(--gray-400)',
          500: 'var(--gray-500)',
          600: 'var(--gray-600)',
          700: 'var(--gray-700)',
          800: 'var(--gray-800)'
        },
        indigo: {
          25: 'var(--indigo-25)',
          50: 'var(--indigo-50)',
          100: 'var(--indigo-100)',
          200: 'var(--indigo-200)',
          300: 'var(--indigo-300)',
          400: 'var(--indigo-400)',
          500: 'var(--indigo-500)',
          600: 'var(--indigo-600)',
          700: 'var(--indigo-700)',
          800: 'var(--indigo-800)',
          900: 'var(--indigo-900)'
        },
        error: {
          300: 'var(--error-300)',
          500: 'var(--error-500)'
        },
        primary: {
          600: 'var(--primary-600)'
        },
        mandarin: {
          25: 'var(--mandarin-25)',
          50: 'var(--mandarin-50)',
          600: 'var(--mandarin-600)',
          900: 'var(--mandarin-900)'
        },
        warning: {
          100: 'var(--warning-100)',
          300: 'var(--warning-300)',
          600: 'var(--warning-600)'
        }
      },
      fontSize: {
        'xxs': '10px',
        'display-sm': '30px',
        'display-xs': '24px'
      }
    },
  },
  plugins: [],
}

