import { renderToString } from "react-dom/server";
import { Check, ChevronDown, Folder, X } from "./components/icons";
import { SvgRegistry } from "survey-core";

SvgRegistry.registerIconFromSvg(
  "chevron",
  renderToString(<ChevronDown color="var(--gray-500)" />)
);

SvgRegistry.registerIconFromSvg(
  "choosefile",
  renderToString(<Folder color="var(--indigo-500)" />)
);

SvgRegistry.registerIconFromSvg(
  "icon-v2check",
  renderToString(<Check size={10} color="var(--indigo-500)" />)
);

SvgRegistry.registerIconFromSvg(
  "icon-delete",
  renderToString(<X color="var(--gray-300)" />)
);
