import { SurveyModel } from "survey-core";
import { FC } from "react";
import { NavMenu } from "../molecules";

interface FooterProps {
  survey: SurveyModel;
  showNavMenu: boolean;
}

export const Footer: FC<FooterProps> = ({ survey, showNavMenu }) => {
  return (
    <div className="fixed w-full bottom-0 z-30 bg-white py-[17px] px-[30px]">
      <NavMenu survey={survey} visible={showNavMenu} />
    </div>
  );
};
