import { FC } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";

export const Pagination: FC<ReactPaginateProps> = (props) => {
  return (
    <ReactPaginate
      {...props}
      previousClassName="hidden"
      nextClassName="hidden"
      breakLabel="..."
      breakClassName="text-xs font-semibold text-indigo-500"
      containerClassName="flex gap-2.5 items-center"
      pageLinkClassName="px-[14px] py-2 rounded-lg border border-indigo-200"
      nextLinkClassName="text-sm font-semibold text-indigo-700"
      activeLinkClassName="bg-indigo-50"
    />
  );
};
