import { FC } from "react";
import { StartButton } from "../../../atoms";

interface ExplainerFooterProps {
  onClickNext: () => void;
}

export const ExplainerFooter: FC<ExplainerFooterProps> = ({ onClickNext }) => {
  return (
    <div>
      <StartButton onClick={onClickNext} label="button-begin-questionnaire" />
    </div>
  );
};
