import { surveyLocalization } from "survey-core";

const engLocale = surveyLocalization.locales["en"];

engLocale.chooseFileCaption = "%-%tolgee:upload-file-button%-%";
engLocale.noEntriesText = "%-%tolgee:no-entries-text%-%";
engLocale.addRow = "%-%tolgee:add-button%-%";
engLocale.addPanel = "%-%tolgee:add-button%-%";
engLocale.removePanel = "%-%tolgee:remove-button%-%";
engLocale.placeholder = "%-%tolgee:dropdown-placeholder%-%";
engLocale.otherItemText = "%-%tolgee:answer-other%-%";
engLocale.noneItemText = "%-%tolgee:answer-none%-%";
