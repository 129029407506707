import { IconProps } from "react-feather";

export function Coal({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4536 6.84989L10.4416 6.85641L10.4371 6.85836L9.38074 7.40199L6.78406 7.78396C6.6308 7.80612 6.49019 7.8863 6.39013 8.00819L2.14994 13.1661C2.12461 13.1961 2.10244 13.2287 2.08408 13.2626L2.08091 13.2685C2.03468 13.3525 2.00617 13.4477 2.00111 13.5494L2.00048 13.5631C1.99984 13.5794 1.99984 13.5957 2.00048 13.612L2.00111 13.6315C2.00554 13.7 2.02074 13.7658 2.04418 13.8271L3.19685 16.9898C3.27348 17.2009 3.45081 17.3561 3.66552 17.4004L5.55412 17.7889L8.9868 18.9668C9.05141 18.9889 9.11854 19 9.18694 19H12.2605H12.2757H12.2821H13.0421C13.0598 19 13.0769 18.9993 13.0946 18.998L17.7205 18.6011C17.87 18.588 18.0099 18.5216 18.1157 18.4127L20.8061 15.6431C20.8581 15.5916 20.9024 15.5303 20.9353 15.4606L20.9429 15.4436L20.9499 15.4286L20.955 15.4162C20.9866 15.3341 21.0012 15.2487 20.9999 15.1653V11.2067C20.9999 11.0861 20.9676 10.9675 20.9062 10.8652L17.8218 5.70658C17.7211 5.53841 17.5514 5.42629 17.3608 5.40152L14.2771 5.00521C14.1529 4.98891 14.0269 5.01108 13.9154 5.06844L10.4536 6.84989ZM3.54708 14.239H9.92098L11.3206 17.6963H9.28954L5.91766 16.5393C5.89233 16.5309 5.86699 16.5243 5.84166 16.5185L4.25895 16.1932L3.54708 14.239ZM19.2728 15.3784L17.3842 17.3215L13.0155 17.6963H12.6931L11.0584 13.6583L12.1136 12.0293L19.2728 15.3784ZM11.4954 7.77158L14.31 6.32321L16.9022 6.6563L19.7333 11.3912V14.1621L12.4043 10.7335L11.4954 7.77158ZM10.3485 8.36148L11.1902 11.105L10.0046 12.9354H3.99865L7.20143 9.03939L9.66194 8.67762C9.72907 8.66784 9.79494 8.64698 9.85574 8.61504L10.3485 8.36148Z"
        fill={color}
      />
    </svg>
  );
}
