import { T } from "@tolgee/react";
import classNames from "classnames";
import { FC } from "react";

interface NavItemLabelProps {
  className?: string;
  label: string;
}

export const NavItemLabel: FC<NavItemLabelProps> = ({ className, label }) => {
  return (
    <span
      className={classNames(
        "text-sm font-medium whitespace-normal text-wrap",
        className
      )}
    >
      {label.startsWith("%-%tolgee:") ? label : <T keyName={label} />}
    </span>
  );
};
