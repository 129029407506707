import { IconProps } from "react-feather";

export function GarmentDyeing({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={size} height={size} fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.74917 19.8277H18.2508C18.945 19.8277 19.5543 19.435 19.841 18.8027C20.1277 18.1704 20.0216 17.4534 19.5642 16.9315L17.589 14.6751C17.5848 14.67 17.5804 14.6651 17.576 14.6603L13.9179 10.4814V7.01531H13.9943C14.8255 7.01531 15.5019 6.33896 15.5019 5.5077C15.5019 4.67635 14.8255 4 13.9943 4H10.0057C9.17448 4 8.49813 4.67635 8.49813 5.5077C8.49813 6.33896 9.17448 7.01531 10.0057 7.01531H10.0821V10.4814L6.78535 14.2474C6.77425 14.2587 6.76373 14.2707 6.75386 14.2834L4.43586 16.9313C3.97841 17.4534 3.87234 18.1704 4.15899 18.8027C4.44565 19.435 5.05498 19.8277 5.74917 19.8277ZM7.19391 15.059L5.06928 17.486C4.82884 17.7606 4.77523 18.1228 4.92587 18.455C5.07652 18.7873 5.38431 18.9857 5.74917 18.9857H18.2508C18.6157 18.9857 18.9235 18.7873 19.0741 18.455C19.2248 18.1228 19.1712 17.7606 18.9308 17.4862L17.0343 15.3198C16.6276 15.2068 16.3121 15.0555 16.0059 14.9087L16.0057 14.9085L16.0056 14.9085C15.4635 14.6485 14.9514 14.4029 13.9062 14.4029C12.8602 14.4029 12.3475 14.6486 11.8047 14.9088L11.8046 14.9089C11.229 15.1848 10.5765 15.4975 9.33906 15.4975C8.30804 15.4975 7.69441 15.2856 7.19391 15.059ZM7.77767 14.3922C8.16478 14.5438 8.62369 14.6555 9.33906 14.6555C10.385 14.6555 10.8978 14.4098 11.4405 14.1496L11.4406 14.1495C12.0162 13.8736 12.6687 13.5609 13.9062 13.5609C14.7476 13.5609 15.3183 13.7056 15.7783 13.885L13.1801 10.9169C13.1128 10.8402 13.0758 10.7417 13.0758 10.6396V6.59429C13.0758 6.36174 13.2643 6.17327 13.4968 6.17327H13.9943C14.3612 6.17327 14.6598 5.87469 14.6598 5.5077C14.6598 5.14062 14.3612 4.84204 13.9943 4.84204H10.0057C9.63875 4.84204 9.34017 5.14062 9.34017 5.5077C9.34017 5.87469 9.63875 6.17327 10.0057 6.17327H10.5032C10.7357 6.17327 10.9242 6.36174 10.9242 6.59429V10.6396C10.9242 10.7417 10.8872 10.8402 10.8199 10.9169L7.77767 14.3922Z"
        fill={color}
      />
    </svg>
  );
}
