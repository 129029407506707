import { FC, createElement } from "react";
import { QuestionRadiogroupModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { RadioGroup } from "../ui";

interface RadioGroupQuestionProps {
  question: QuestionRadiogroupModel;
}

const RadioGroupQuestion: FC<RadioGroupQuestionProps> = ({ question }) => {
  const {
    dataChoices,
    isReadOnly,
    defaultValue,
    required,
    ariaDescribedBy,
    ariaErrormessage,
    ariaInvalid,
    ariaRequired,
  } = question;

  const onValueChange = (newVal: string) => (question.value = newVal);

  return (
    <RadioGroup
      required={required}
      items={dataChoices}
      disabled={isReadOnly}
      defaultValue={defaultValue}
      aria-describedby={ariaDescribedBy}
      aria-errormessage={ariaErrormessage}
      aria-invalid={ariaInvalid}
      aria-required={ariaRequired}
      onValueChange={onValueChange}
      value={question.value}
      name={question.getValueName()}
    />
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-radio-group-question",
  function (props: any) {
    return createElement(RadioGroupQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "radiogroup",
  "radio-group-question",
  "sv-radio-group-question"
);
