import { createElement, FC, useCallback, useEffect } from "react";
import { QuestionDropdownModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { useTolgee } from "@tolgee/react";
import { LanguageSelect } from "../atoms";

interface LanguageDropdownQuestionProps {
  question: QuestionDropdownModel;
}

const LanguageDropdownQuestion: FC<LanguageDropdownQuestionProps> = ({
  question,
}) => {
  const tolgee = useTolgee();

  useEffect(() => {
    if (!question.defaultValue) question.defaultValue = tolgee.getLanguage();
  });

  const onValueChange = useCallback(
    (newVal: string) => {
      question.value = newVal;
    },
    [question]
  );

  return (
    <LanguageSelect
      className="w-full md:w-fit"
      onLanguageChange={onValueChange}
    />
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-language-dropdown-question",
  function (props: any) {
    return createElement(LanguageDropdownQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "dropdown",
  "language-dropdown-question",
  "sv-language-dropdown-question"
);
