import { T } from "@tolgee/react";
import { FC, createElement } from "react";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";

interface PQProductDetailsTitleExpressionProps {
  question: QuestionExpressionModel;
}

const PQProductDetailsTitleExpression: FC<
  PQProductDetailsTitleExpressionProps
> = ({ question }) => {
  const index = question.parent.getQuestionStartIndex();
  return (
    <div className="flex gap-2.5 items-center">
      <div className="grid w-5 h-5 text-xs leading-[18px] font-semibold text-indigo-600 rounded-full place-items-center bg-indigo-50">
        {index}
      </div>
      <div className="text-xs md:text-sm leading-[18px] leading-6 font-medium text-gray-800 capitalize">
        <T keyName="pq-product-details-panel-title" />
      </div>
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-pq-product-details-title-expression",
  function (props: any) {
    return createElement(PQProductDetailsTitleExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "pq-product-details-title-expression",
  "sv-pq-product-details-title-expression"
);
