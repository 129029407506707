import { useCallback, useContext, useState } from "react";
import { ApiServiceContext } from "../contexts";
import { FileQuestion, Metadata } from "../types";
import axios from "axios";

export const useFileUpload = ({
  initFiles = [],
  allowMultiple,
}: {
  initFiles?: FileQuestion[];
  allowMultiple?: boolean;
}) => {
  const { apiService } = useContext(ApiServiceContext);
  const [files, setFiles] = useState(initFiles);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setError] = useState<string>("");

  const uploadFiles = useCallback(
    async (fileList: FileList, answerKey: string, metadata: Metadata) => {
      if (fileList?.length > 0) {
        setIsUploading(true);
        const formData = new FormData();
        Array.from(fileList).forEach((file) => {
          formData.append("files", file);
        });
        formData.append(
          "metadata",
          JSON.stringify({ ...metadata, allowMultiple })
        );
        try {
          const { data: uploadedFiles } = await apiService.uploadFile(
            answerKey,
            formData
          );
          if (uploadedFiles.length > 0)
            setFiles((files) => [...files, ...uploadedFiles]);
        } catch (e: unknown) {
          if (axios.isAxiosError(e)) {
            setError(e.message);
          } else {
            setError(`Unexpected error occured: ${e}`);
          }
        } finally {
          setIsUploading(false);
        }
      }
    },
    [allowMultiple, apiService]
  );

  const removeFile = useCallback(
    async (fileId: string) => {
      setIsRemoving(true);
      try {
        await apiService.removeFile(fileId);
        setFiles((files) => files.filter((file) => file.id !== fileId));
      } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
          setError(e.message);
        } else {
          setError(`Unexpected error occured: ${e}`);
        }
      } finally {
        setIsRemoving(false);
      }
    },
    [apiService]
  );

  const downloadFile = useCallback(
    async (fileId: string) => {
      try {
        const { data } = await apiService.getFileDownloadUrl(fileId);
        window.open(data.url, "_blank");
      } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
          setError(e.message);
        } else {
          setError(`Unexpected error occured: ${e}`);
        }
      }
    },
    [apiService]
  );

  const cleanError = useCallback(() => {
    setError("");
  }, []);

  return {
    uploadFiles,
    removeFile,
    isUploading,
    isRemoving,
    error,
    setError,
    files,
    cleanError,
    downloadFile,
  };
};
