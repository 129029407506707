import { T } from "@tolgee/react";
import classNames from "classnames";
import { FC } from "react";

interface CurrentSectionBadgeProps {
  className?: string;
}

export const CurrentSectionBadge: FC<CurrentSectionBadgeProps> = ({
  className,
}) => {
  return (
    <div
      className={classNames(
        "md:inline-block hidden w-fit rounded-[16px] py-0.5 px-2 font-medium text-xxs text-center ",
        className
      )}
    >
      <T keyName="current-section" />
    </div>
  );
};
