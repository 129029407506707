import { IconProps } from "react-feather";

export function Bio({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        d="M21 10.9646V3H13.0354C11.5462 3.00132 10.0873 3.42011 8.82414 4.20882C7.56099 4.99752 6.54427 6.12456 5.88938 7.46199C5.23449 8.79942 4.96767 10.2937 5.11921 11.7751C5.27074 13.2565 5.83457 14.6658 6.74668 15.8429L3.70307 18.8892C3.31473 19.2779 3.31487 19.9078 3.70338 20.2963C4.09202 20.6849 4.72213 20.6849 5.11077 20.2963L8.15708 17.25C9.33399 18.1619 10.743 18.7257 12.2241 18.8774C13.7052 19.0291 15.1992 18.7625 16.5365 18.108C17.8738 17.4536 19.0009 16.4373 19.7899 15.1747C20.5788 13.912 20.9981 12.4535 21 10.9646ZM13.0354 16.9381C11.7953 16.9386 10.5861 16.551 9.57743 15.8296L14.4027 11.0044C14.7912 10.6159 14.7912 9.9859 14.4027 9.59734C14.0141 9.20879 13.3841 9.20879 12.9956 9.59735L8.17035 14.4226C7.53613 13.5292 7.15981 12.4786 7.08256 11.3858C7.00531 10.2929 7.23011 9.19984 7.73237 8.22615C8.23463 7.25247 8.995 6.43567 9.9303 5.86511C10.8656 5.29455 11.9398 4.9922 13.0354 4.99115H19.0089V10.9646C19.0089 12.5489 18.3795 14.0682 17.2593 15.1885C16.139 16.3087 14.6197 16.9381 13.0354 16.9381Z"
        fill={color}
      />
    </svg>
  );
}
