import { ChangeEventHandler, FC, useCallback, useRef, useState } from "react";
import * as Icon from "../../icons";
import classNames from "classnames";
import { FileQuestion } from "../../../types";

interface MiniFileUploadProps {
  name: string;
  onUploadFiles: ChangeEventHandler<HTMLInputElement>;
  onRemoveFile: (fileId: string) => void;
  onDownloadFile: (fileId: string) => void;
  allowMultiple?: boolean;
  acceptedTypes?: string;
  disabled?: boolean;
  files: FileQuestion[];
  isUploading?: boolean;
  isRemoving?: boolean;
  error?: string;
  onCleanError?: () => void;
}

export const MiniFileUpload: FC<MiniFileUploadProps> = ({
  name,
  onUploadFiles,
  onRemoveFile,
  onDownloadFile,
  allowMultiple,
  acceptedTypes,
  disabled,
  files,
  isUploading,
  isRemoving,
  error,
  onCleanError,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const hasError = error !== undefined && error.length > 0;

  const handleUploadClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | SVGElement>) => {
      if (fileInputRef.current && !disabled) {
        e.preventDefault();
        fileInputRef.current.click();
      }
    },
    [disabled]
  );

  const handleResetError = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.preventDefault();
      if (onCleanError) onCleanError();
    },
    [onCleanError]
  );

  const handlePrevClick = useCallback(
    (e: React.MouseEvent<SVGElement>) =>
      setCurrentIndex((currentIndex - 1 + files.length) % files.length),
    [currentIndex, files.length]
  );

  const handleNextClick = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % files.length);
  }, [currentIndex, files.length]);

  const handleRemoveFile = useCallback(
    (e: React.MouseEvent<SVGElement>, fileId: string) => {
      e.preventDefault();
      if (fileInputRef.current) fileInputRef.current.value = "";
      onRemoveFile(fileId);
      setCurrentIndex((currentIndex) =>
        currentIndex > 0 ? --currentIndex : currentIndex
      );
    },
    [onRemoveFile]
  );

  return (
    <div className="flex w-full">
      <input
        ref={fileInputRef}
        name={name}
        type="file"
        accept={acceptedTypes}
        hidden
        onChange={onUploadFiles}
        multiple={allowMultiple}
        disabled={disabled || isUploading || isRemoving || hasError}
      />
      <button
        className={`appearance-none outline-none py-2 md:py-2.5 w-full grow-0 text-xs md:text-sm leading-5 font-semibold
            ${files.length > 0 ? "cursor-default" : ""}
          ${
            disabled
              ? "text-gray-400 bg-gray-50 cursor-not-allowed"
              : "text-indigo-700 bg-white"
          } border border-indigo-200 select-none px-[14px] rounded-lg border-gray-200`}
        onClick={
          files.length === 0 ? handleUploadClick : (e) => e.preventDefault()
        }
      >
        <div className="flex items-center justify-between">
          {!isUploading && files.length === 0 && !error && (
            <div>%-%tolgee:upload-file-button%-%</div>
          )}
          {isUploading && (
            <div className="flex items-center">
              <Icon.Loader
                className="animate-spin"
                size={16}
                color="var(--indigo-300)"
              />
              <div>%-%tolgee:uploading-file-in-progress-message%-%</div>
            </div>
          )}
          {isRemoving && (
            <div className="flex items-center">
              <Icon.Loader
                className="animate-spin"
                size={20}
                color="var(--indigo-300)"
              />
              <div>%-%tolgee:removing-file-in-progress-message%-%</div>
            </div>
          )}
          {error && (
            <div className="flex items-center w-full shrink-0 ">
              <Icon.X
                className="shrink-0"
                color="var(--error-300)"
                size={16}
                onClick={handleResetError}
              />
              <div className="truncate text-error-500">{error}</div>
            </div>
          )}
          {!error && files.length > 0 && !isUploading && !isRemoving && (
            <div className={`grid grid-cols-10 items-center w-full`}>
              <div
                className={`${files.length > 1 ? "col-span-8" : "col-span-10"}`}
              >
                <FileList
                  files={files}
                  onDownloadFile={onDownloadFile}
                  currentIndex={currentIndex}
                  disabled={disabled}
                  handleRemoveFile={handleRemoveFile}
                />
              </div>
              {files.length > 1 && (
                <div className="flex items-center col-span-2 justify-self-end">
                  <Icon.ChevronLeft
                    className="cursor-pointer"
                    onClick={handlePrevClick}
                    color="var(--indigo-400)"
                    size={20}
                  />
                  <Icon.ChevronRight
                    className="cursor-pointer"
                    onClick={handleNextClick}
                    color="var(--indigo-400)"
                    size={20}
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex items-center">
            {(files.length === 0 || allowMultiple) && !error && (
              <Icon.Upload
                className={classNames({
                  "cursor-pointer": files.length > 0 && !disabled,
                  "cursor-not-allowed": disabled,
                })}
                onClick={files.length > 0 ? handleUploadClick : undefined}
                color={`${disabled ? "var(--gray-400)" : "var(--indigo-700)"}`}
                size={20}
              />
            )}
          </div>
        </div>
      </button>
    </div>
  );
};

interface FileListProps {
  files: FileQuestion[];
  onDownloadFile: (fileId: string) => void;
  currentIndex: number;
  disabled?: boolean;
  handleRemoveFile: (e: React.MouseEvent<SVGElement>, fileId: string) => void;
}

const FileList: FC<FileListProps> = ({
  files,
  onDownloadFile,
  currentIndex,
  disabled,
  handleRemoveFile,
}) => {
  return (
    <ul className="w-full">
      {files.map((file, index) => (
        <li
          key={file.id}
          className={classNames("hidden", {
            "!block": index === currentIndex,
          })}
        >
          <div className="flex items-center">
            {!disabled && (
              <Icon.X
                onClick={(e) => handleRemoveFile(e, file.id)}
                className="cursor-pointer shrink-0"
                color="var(--indigo-500)"
                size={20}
              />
            )}
            <div
              onClick={() => onDownloadFile(file.id)}
              className="text-xs truncate md:text-sm hover:underline hover:cursor-pointer"
            >
              {file.name}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
