import { createElement } from "react";
import { QuestionPanelDynamicModel } from "survey-core";
import {
  ReactElementFactory,
  SurveyQuestionPanelDynamicRemoveButton,
} from "survey-react-ui";
import * as Icon from "../icons";

class DynamicPanelRemoveButton extends SurveyQuestionPanelDynamicRemoveButton {
  protected get panel(): QuestionPanelDynamicModel {
    return this.question as QuestionPanelDynamicModel;
  }

  protected renderElement(): JSX.Element {
    return (
      <div>
        <button
          className="flex gap-[7px] items-center px-[21px] py-[7px]"
          type="button"
          disabled={this.panel.isInputReadOnly}
          onClick={this.handleClick}
        >
          <Icon.MinusCircle color="var(--error-500)" />
          <span className="text-sm font-semibold text-error-500">
            {this.question.panelRemoveText}
          </span>
        </button>
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "sv-paneldynamic-remove-btn",
  (props) => {
    return createElement(DynamicPanelRemoveButton, props);
  }
);
