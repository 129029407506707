import { IconProps } from "react-feather";

export function GarmentWash({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={size} height={size} fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.19305 6.80637C6.19305 7.79617 7.00963 8.61275 7.99943 8.61275C8.98922 8.61275 9.8058 7.79617 9.8058 6.80637C9.8058 5.81658 8.98922 5 7.99943 5C7.00963 5 6.19305 5.81658 6.19305 6.80637ZM6.76219 6.83112C6.76219 6.13826 7.30657 5.59388 7.99943 5.59388C8.69228 5.59388 9.23667 6.13826 9.23667 6.83112C9.23667 7.52397 8.69228 8.06836 7.99943 8.06836C7.30657 8.06836 6.76219 7.52397 6.76219 6.83112Z"
        fill="#4975B6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6224 9.25609C10.6224 9.77857 10.7366 10.2684 10.9651 10.7124C10.1627 10.9157 9.29978 10.906 8.49433 10.6666C7.43031 10.3201 6.44051 10.2706 5.57444 10.4933H5.5497C5.51181 10.4933 5.47392 10.5079 5.43602 10.5258L4.53514 8.21683C4.46091 8.09311 4.31244 8.01887 4.16397 8.06836C4.04025 8.1426 3.96601 8.29107 4.0155 8.43954L7.35606 16.9765C7.70248 17.8673 8.54381 18.4365 9.50886 18.4365H14.5568C15.4476 18.4365 16.2642 17.9168 16.6601 17.1002L20.644 8.43954C20.6935 8.29107 20.644 8.1426 20.4956 8.06836C20.3471 8.01887 20.1986 8.06836 20.1244 8.21683L19.0269 10.6026C18.5181 10.7491 18.0051 10.632 17.4272 10.4934C17.3901 10.481 17.353 10.4748 17.3159 10.4686C17.2788 10.4624 17.2416 10.4562 17.2045 10.4439H17.1798C17.0273 10.4235 16.8791 10.4283 16.7333 10.446C16.8825 10.068 16.9571 9.66205 16.9571 9.25609C16.9571 7.4992 15.5466 6.08875 13.7897 6.08875C12.0328 6.08875 10.6224 7.52395 10.6224 9.25609ZM13.765 12.4234C12.7738 12.4234 11.8471 11.9725 11.2648 11.1907C10.8056 11.3087 10.3275 11.3842 9.83056 11.3842C9.33566 11.3842 8.81602 11.3099 8.32112 11.1615C7.35607 10.8645 6.49 10.815 5.72291 11.013C5.71266 11.013 5.70241 11.0172 5.6904 11.0222C5.67579 11.0283 5.65858 11.0354 5.6356 11.0373L7.8757 16.7785C8.14789 17.4467 8.76651 17.8921 9.50886 17.8921H14.5568C15.2249 17.8921 15.8683 17.4962 16.1405 16.8775L18.7387 11.2293C18.6716 11.234 18.6053 11.2357 18.5407 11.2357C18.1201 11.2357 17.6994 11.1367 17.3035 11.0377C17.2664 11.0254 17.2354 11.0192 17.2045 11.013C17.1736 11.0068 17.1427 11.0006 17.1055 10.9882C16.872 10.967 16.6384 11.0187 16.3892 11.0807C15.7681 11.9301 14.8018 12.4234 13.765 12.4234ZM13.765 6.65788C12.3298 6.65788 11.1668 7.82088 11.1668 9.25609C11.1668 9.77573 11.3152 10.2706 11.5874 10.6913C12.0823 11.4089 12.8742 11.8543 13.765 11.8543C14.631 11.8543 15.4476 11.4089 15.9425 10.6913C16.2147 10.2706 16.3632 9.77573 16.3632 9.25609C16.3632 7.82088 15.2002 6.65788 13.765 6.65788Z"
        fill={color}
      />
    </svg>
  );
}
