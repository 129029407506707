import {
  ReactElementFactory,
  SurveyQuestionCheckboxItem,
} from "survey-react-ui";
import { createElement } from "react";
import { Checkbox } from "../ui/checkbox";

class SimpleCheckboxItem extends SurveyQuestionCheckboxItem {
  handleOnChange = (checked: boolean) => {
    if (
      !this.question.isReadOnly &&
      this.item.isEnabled &&
      this.question.page.name !== "all"
    )
      this.props.question.clickItemHandler(this.props.item, checked);
  };

  constructor(props: any) {
    super(props);
    this.state = {
      question: props.question,
      item: props.item,
    };
  }

  render() {
    const disabled =
      this.question.isReadOnly ||
      !this.item.isEnabled ||
      this.question.page.name === "all"; //all = preview page
    return (
      <div className="mb-[15px]">
        <Checkbox
          label={this.item.title}
          checked={this.item.selected}
          disabled={disabled}
          id={this.question.getValueName() ?? this.question.name}
          onCheckedChange={this.handleOnChange}
        />
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "simple-checkbox-item",
  (props) => {
    return createElement(SimpleCheckboxItem, props);
  }
);
