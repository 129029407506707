/* eslint-disable no-process-env */

import { appConfigSchema } from "./types";

export const appConfig = getAppConfig();

function getAppConfig() {
  return appConfigSchema.parse({
    surveyApi: process.env.REACT_APP_API_URL,
    tolgeeApiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    tolgeeApiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    availableLanguages: JSON.parse(
      process.env.REACT_APP_AVAILABLE_LANGUAGES || '["en"]'
    ),
    paginationConfig: {
      maxItemsPerPage: process.env.REACT_APP_PAGINATION_MAX_ITEMS_PER_PAGE,
      itemsPerPage: process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE,
      selectedPage: process.env.REACT_APP_PAGINATION_SELECTED_PAGE,
      displayInterval: process.env.REACT_APP_PAGINATION_DISPLAY_INTERVAL,
      pageCounter: process.env.REACT_APP_PAGINATION_PAGE_COUNTER,
    },
    maxFileSize: parseInt(process.env.REACT_APP_MAX_FILE_SIZE as string),
  });
}
