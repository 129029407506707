export const LogoWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="24"
      viewBox="0 0 124 24"
      fill="none"
    >
      <path
        d="M7.85008 16.8816L10.3662 0.159241H15.4984V23.7123H12.0039V6.83066L9.45282 23.7123H5.95825L3.21977 7.06318V23.7123H0V0.168796H5.13226L7.85008 16.8816Z"
        fill="white"
      />
      <path
        d="M30.1231 23.7123H26.3998L25.7645 19.4409H21.2326L20.5973 23.7123H17.2075L20.9674 0.168793H26.3681L30.1231 23.7123ZM21.7044 16.2445H25.2562L23.4771 4.33823L21.7044 16.2445Z"
        fill="white"
      />
      <path
        d="M31.834 0.168793H37.6699C41.3615 0.168793 43.1723 2.22007 43.1723 5.98657V17.8929C43.1723 21.6594 41.3615 23.7123 37.6699 23.7123H31.834V0.168793ZM35.5239 3.53078V20.3487H37.6032C38.7771 20.3487 39.4823 19.7435 39.4823 18.0617V5.81935C39.4823 4.13756 38.7771 3.53078 37.6032 3.53078H35.5239Z"
        fill="white"
      />
      <path
        d="M49.21 10.0907H54.2755V13.4527H49.21V20.3487H55.5828V23.7123H45.52V0.168793H55.5828V3.53078H49.21V10.0907Z"
        fill="white"
      />
      <path
        d="M67.6675 6.02004C67.6675 12.5784 58.7436 14.765 58.6134 22.8714V23.0052H67.5246V23.7123H57.8636V23.2074C57.8636 14.3955 66.9178 12.3777 66.9178 6.05349C66.9178 3.09443 65.979 0.672079 62.7243 0.672079C59.5712 0.672079 58.5657 3.22821 58.5657 6.05349V7.32757H57.8605V6.08693C57.8605 2.82528 59.1312 0 62.7576 0C66.4524 0 67.6675 2.68991 67.6675 6.02004Z"
        fill="white"
      />
      <path
        d="M77.5891 12.0066V12.7137H71.0129V23.7123H70.2759V0.168793H78.8963V0.874316H71.0129V12.0066H77.5891Z"
        fill="white"
      />
      <path
        d="M81.4458 23.7123V0.168793H82.1844V23.0051H90.0647V23.7123H81.4458Z"
        fill="white"
      />
      <path
        d="M92.5823 5.95315C92.5823 2.65646 93.9912 0 97.5477 0C101.169 0 102.578 2.65646 102.578 5.95315V17.9264C102.578 21.223 101.169 23.8795 97.5477 23.8795C93.9912 23.8795 92.5823 21.223 92.5823 17.9264V5.95315ZM93.3209 17.9598C93.3209 20.8536 94.4328 23.2154 97.5477 23.2154C100.701 23.2154 101.837 20.8615 101.837 17.9678V5.91971C101.837 3.02754 100.696 0.672079 97.5477 0.672079C94.4265 0.672079 93.3209 3.02754 93.3209 5.92767V17.9598Z"
        fill="white"
      />
      <path
        d="M110.429 23.7123H109.355L105.128 0.168793H105.867L109.958 22.7025L114.018 0.302571H114.924L118.747 22.5688L122.706 0.168793H123.378L119.217 23.7123H118.244L114.454 1.9111L110.429 23.7123Z"
        fill="white"
      />
    </svg>
  );
};
