import { Logo, LogoMini, Menu2 } from "../icons";
import { useAtomValue, useSetAtom } from "jotai";
import {
  isMobileDrawerOpenAtom,
  questionnaireNameAtom,
} from "../../jotai-atoms";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Question } from "survey-core";
import { useBreakpoint } from "../../hooks";
import { LanguageSelect, MobileToastContainer } from "../atoms";

interface TopBarProps {
  languageQuestion?: Question;
}

export const TopBar: FC<TopBarProps> = ({ languageQuestion }) => {
  const questionnaireName = useAtomValue(questionnaireNameAtom);
  const { isAboveMd, isBelowMd } = useBreakpoint("md");
  const setIsDrawerOpen = useSetAtom(isMobileDrawerOpenAtom);
  const topBarRef = useRef<HTMLDivElement>(null);
  const [topBarHeight, setTopBarHeight] = useState(0);

  useEffect(() => {
    setTopBarHeight(topBarRef.current?.offsetHeight ?? 0);
  }, []);

  const onLanguageChange = useCallback(
    (val: string) => {
      if (languageQuestion) {
        languageQuestion.value = val;
      }
    },
    [languageQuestion]
  );

  return (
    <>
      <div
        ref={topBarRef}
        className="fixed md:h-[94px] w-full z-40 md:shadow-md border-b border-gray-50 bg-white md:px-[33px] md:py-[13px] px-5 py-[17px]"
      >
        <div className="flex items-center justify-between h-full gap-5">
          <div className="flex items-center h-full divide-x divide-x-gray-200">
            <div className="md:pr-6 pr-2.5">
              {isAboveMd ? <Logo /> : <LogoMini />}
            </div>
            <div className="md:text-base text-xs md:pl-6 pl-2.5 font-medium md:leading-[30px] leading-[16px] text-gray-700 text-wrap">
              {questionnaireName}
            </div>
          </div>
          <div>
            {isAboveMd ? (
              <LanguageSelect
                className="max-w-[150px] min-w-[150px]"
                onLanguageChange={onLanguageChange}
              />
            ) : (
              <Menu2
                color="var(--indigo-500)"
                size={20}
                onClick={() => setIsDrawerOpen(true)}
              />
            )}
          </div>
        </div>
      </div>
      {isBelowMd && (
        <MobileToastContainer
          style={{
            top: topBarHeight,
          }}
        />
      )}
    </>
  );
};
