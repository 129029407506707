import { FC, useMemo } from "react";
import { useBreakpoint } from "../../../../hooks";
import { ExplainerExpression } from "../../../../types";

interface ExplainerContentProps {
  data: ExplainerExpression;
}

export const ExplainerContent: FC<ExplainerContentProps> = ({ data }) => {
  const { points, explainerImage } = data;
  const { isBelowSm } = useBreakpoint("sm");
  const { isBelowMd } = useBreakpoint("md");
  const { isBelowLg } = useBreakpoint("lg");
  const { isBelowXl } = useBreakpoint("xl");

  const size = useMemo(() => {
    if (explainerImage && explainerImage.size) {
      const { sm, md, lg, xl, xxl } = explainerImage.size;
      if (isBelowSm) {
        return {
          width: sm.width,
          height: sm.height,
        };
      } else if (isBelowMd) {
        return {
          width: md.width,
          height: md.height,
        };
      } else if (isBelowLg) {
        return {
          width: lg.width,
          height: lg.height,
        };
      } else if (isBelowXl) {
        return {
          width: xl.width,
          height: xl.height,
        };
      } else {
        return {
          width: xxl.width,
          height: xxl.height,
        };
      }
    }
  }, [explainerImage, isBelowLg, isBelowMd, isBelowSm, isBelowXl]);

  return (
    <div className="flex flex-col justify-center w-full h-full">
      <span className="text-base font-medium text-gray-800 whitespace-normal md:text-lg">
        {data.title}
      </span>
      <ul className="pt-[15px] list-disc list-inside">
        {points?.map((point, index) => (
          <li
            key={index}
            className="text-sm text-gray-500 whitespace-normal font-regular md:text-base"
          >
            {point}
          </li>
        ))}
      </ul>
      {explainerImage && (
        <div className="flex justify-center">
          <img
            id="explainer-image"
            src={explainerImage.src}
            alt="explainer"
            className={`pt-[45px]`}
            width={size?.width}
            height={size?.height}
          />
        </div>
      )}
    </div>
  );
};
