import { MenuItem } from "../types";

export const items: MenuItem[] = [
  {
    id: "1",
    icon: "Facility",
    title: "%-%tolgee:gi-page-title%-%",
    pages: [
      {
        name: "general-information-page",
      },
    ],
  },
  {
    id: "2",
    icon: "Facility",
    title: "%-%tolgee:pp-page-title%-%",
    pages: [
      {
        name: "production-and-processes-page",
      },
      {
        name: "production-and-processes-page-2",
      },
    ],
  },
  {
    id: "3",
    icon: "Energy",
    title: "%-%tolgee:ee-page-title%-%",
    pages: [
      {
        name: "energy-and-utility-page",
      },
      {
        name: "energy-and-utility-page-2",
      },
    ],
  },
  {
    id: "4",
    icon: "Water",
    title: "%-%tolgee:w-page-title%-%",
    pages: [
      {
        name: "water-page",
      },
      {
        name: "water-page-2",
      },
    ],
  },
  {
    id: "5",
    icon: "WasteWater",
    title: "%-%tolgee:ww-page-title%-%",
    pages: [
      {
        name: "ww-page",
      },
    ],
  },
  {
    id: "6",
    icon: "DryWaste",
    title: "%-%tolgee:dw-page-title%-%",
    pages: [
      {
        name: "dry-waste-page",
      },
    ],
  },
];
