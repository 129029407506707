import { FC, createElement } from "react";
import { QuestionMatrixDynamicModel } from "survey-core";
import { ReactElementFactory } from "survey-react-ui";
import * as Icon from "../icons";

interface MatrixDynamicAddButtonProps {
  question: QuestionMatrixDynamicModel;
}

const MatrixDynamicAddButton: FC<MatrixDynamicAddButtonProps> = ({
  question,
}) => {
  const { addRowText, isReadOnly, maxRowCount, rowCount } = question;

  return (
    <div className="pt-[20px]">
      <button
        className="flex gap-[7px] items-center"
        type="button"
        disabled={isReadOnly || rowCount >= maxRowCount}
        onClick={() => question.addRow(true)}
      >
        <Icon.PlusCircle color="var(--indigo-500)" />
        <span className="text-sm font-semibold text-indigo-500">
          {addRowText}
        </span>
      </button>
    </div>
  );
};

ReactElementFactory.Instance.registerElement(
  "sv-matrixdynamic-add-btn",
  (props) => {
    return createElement(MatrixDynamicAddButton, props);
  }
);
