import { Outlet } from "react-router-dom";
import { useAtomValue } from "jotai";
import { isNewQuestionnaireAtom, languageQuestionAtom } from "../jotai-atoms";
import classNames from "classnames";
import { TopBar } from "../components/organisms";

export const App = () => {
  const showIsNewQuestionnaire = useAtomValue(isNewQuestionnaireAtom);
  const languageQuestion = useAtomValue(languageQuestionAtom);

  return (
    <div
      className={classNames("min-h-screen", {
        "bg-dark-blue-gradient bg-no-repeat bg-cover": showIsNewQuestionnaire,
      })}
    >
      {!showIsNewQuestionnaire && (
        <TopBar languageQuestion={languageQuestion} />
      )}
      <main>
        <Outlet />
      </main>
    </div>
  );
};
