import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { ErrorPageTemplate } from "../components/templates";

export const ErrorPage = () => {
  const error = useRouteError();
  const [errorTsKey, setErrorTsKey] = useState("");

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      setErrorTsKey(error.data);
    } else if (error instanceof AxiosError) {
      setErrorTsKey(error.message);
    } else if (error instanceof Error) {
      setErrorTsKey(error.message);
    }
  }, [error]);

  return <ErrorPageTemplate errorMessage={errorTsKey} />;
};
