import {
  ReactElementFactory,
  SurveyQuestionCheckboxItem,
} from "survey-react-ui";
import * as Checkbox from "@radix-ui/react-checkbox";
import { createElement } from "react";
import classNames from "classnames";
import * as Icon from "../icons";

class BorderedCheckboxItem extends SurveyQuestionCheckboxItem {
  handleOnChange = (checked: boolean) => {
    if (
      !this.question.isReadOnly &&
      this.item.isEnabled &&
      this.question.page.name !== "all"
    )
      this.props.question.clickItemHandler(this.props.item, checked);
  };

  handleLabelClick = () => {
    const isEnabled = this.item.isEnabled && this.question.page.name !== "all";
    if (isEnabled) this.handleOnChange(!this.item.selected);
  };

  render() {
    const { item, question } = this.props;
    const disabled =
      this.question.isReadOnly ||
      !item.isEnabled ||
      question.page.name === "all";
    return (
      <div className="flex items-center gap-2.5 mb-5">
        <Checkbox.Root
          disabled={disabled} //all = preview page
          checked={item.selected}
          name={item.name}
          value={item.value}
          onCheckedChange={this.handleOnChange}
          className="hidden group"
        />
        <div
          className={classNames(
            "flex items-center gap-[15px] py-[14px] px-[15px] md:px-4 w-full h-full shrink-0 border border-indigo-100 rounded-[10px] cursor-pointer",
            {
              "!cursor-default": disabled,
              "!bg-gray-50 !border-gray-300": disabled && !item.selected,
              "bg-indigo-500 border-indigo-500": item.selected,
            }
          )}
          onClick={this.handleLabelClick}
        >
          <ItemIcon
            value={this.item.value}
            size={30}
            color={
              this.item.selected
                ? "white"
                : disabled
                ? "var(--gray-300)"
                : "var(--gray-400)"
            }
          />
          <label
            className={classNames(
              "text-sm font-medium whitespace-normal text-ellipsis overflow-hidden leading-5",
              disabled ? "text-gray-300" : "text-gray-800 cursor-pointer",
              {
                "text-white": item.selected,
              }
            )}
          >
            {item.title}
          </label>
        </div>
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "borderd-checkbox-item",
  (props) => {
    return createElement(BorderedCheckboxItem, props);
  }
);

function ItemIcon({ value, ...other }: { value: string } & Icon.IconProps) {
  switch (value) {
    case "Biomass":
    case "Biodiesel":
      return <Icon.Bio {...other} />;
    case "Coal":
      return <Icon.Coal {...other} />;
    case "Diesel":
    case "Petrol":
    case "Fuel Oil":
      return <Icon.Oil {...other} />;
    case "Purchased Steam":
    case "On-site Wind Electricty":
      return <Icon.Wind {...other} />;
    case "Purchased Electricity":
    case "Purchased Electricity (Renewable)":
      return <Icon.Zap {...other} />;
    case "On-site Solar Electricty":
      return <Icon.Sun {...other} />;
    case "Purchased Chilled Water":
    case "Purchased Hot Water":
      return <Icon.Water {...other} />;
    case "Fresh Surface Water":
    case "Rainwater collection":
    case "Groundwater (water well)":
    case "Municipal water supply":
    case "Recycled water (Produced/Process Water)":
    case "Water truck delivery (general or unknown origin)":
    case "Wastewater from another organization":
    case "Brackish surface water/seawater":
      return <Icon.Droplet {...other} />;
    case "Natural Gas":
    case "Liquified Natural Gas (LNG)":
    case "Liquefied Petroleum Gas (LPG)":
    case "Compressed natural gas (CNG)":
      return <Icon.Gas {...other} />;
    case "Wood":
      return <Icon.Wood {...other} />;
    case "Other":
      return <Icon.MoreHorizontal {...other} />;
    default:
      return null;
  }
}
