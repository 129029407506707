import { FunctionFactory } from "survey-core";
import { validateProductHasMainFabric } from "./validate-main-fabric";
import { validateTotal } from "./validate-total";
import { validateRatio } from "./validate-ratio";

FunctionFactory.Instance.register(
  "validateProductHasMainFabric",
  validateProductHasMainFabric
);

FunctionFactory.Instance.register("validateRatio", validateRatio);

FunctionFactory.Instance.register("validateTotal", validateTotal);
