import {
  ChangeEventHandler,
  FC,
  createElement,
  useCallback,
  useEffect,
} from "react";
import { QuestionFileModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { FileUpload, MiniFileUpload } from "../ui";
import { useFileUpload } from "../../hooks";
import { buildFileMetadata } from "../../utils";
import { toast } from "react-toastify";
import { T, useTranslate } from "@tolgee/react";
import { XCircle } from "react-feather";
import { appConfig } from "../../config";
import { useAtomValue } from "jotai";
import { uploadedFilesSetAtom } from "../../jotai-atoms";

interface FileQuestionProps {
  question: QuestionFileModel;
}

// Used as a column inside a matrix or a dynamix pannel questions
const FileQuestion: FC<FileQuestionProps> = ({ question }) => {
  const uploadedFilesSet = useAtomValue(uploadedFilesSetAtom);
  const { allowMultiple, acceptedTypes, name, value, isReadOnly, maxSize } =
    question;
  const {
    uploadFiles,
    files,
    isUploading,
    removeFile,
    downloadFile,
    error,
    setError,
    cleanError,
    isRemoving,
  } = useFileUpload({
    initFiles: value ?? [],
    allowMultiple,
  });
  const { t } = useTranslate();

  const elementName = question.getValueName() ?? name;

  useEffect(() => {
    question.value = files;
  }, [files, question]);

  const updateUploadedFilesSet = useCallback(
    (key: string) => {
      uploadedFilesSet.add(key);
    },
    [uploadedFilesSet]
  );

  const handleRemoveFile = useCallback(
    (fileId: string) => {
      removeFile(fileId).then((res) => {
        updateUploadedFilesSet(elementName);
      });
    },
    [elementName, removeFile, updateUploadedFilesSet]
  );

  const handleUploadFiles: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const fileList = e.target.files;
      cleanError();
      if (fileList) {
        const maxFileSize = maxSize > 0 ? maxSize : appConfig.maxFileSize;
        if (Array.from(fileList).some((file) => file.size > maxFileSize)) {
          if (!question.parentQuestion) {
            setError(t("file-too-big-error-message"));
          } else {
            toast.error(() => <T keyName="file-too-big-error-message" />, {
              icon: <XCircle color="var(--error-500)" />,
            });
          }
          return;
        }
        const parentValueName = question.parentQuestion?.getValueName();
        const metadata = buildFileMetadata(elementName, question);
        const answerKey = parentValueName ?? elementName;
        uploadFiles(fileList, answerKey, metadata).then((res) => {
          updateUploadedFilesSet(elementName);
        });
      }
    },
    [
      cleanError,
      elementName,
      maxSize,
      question,
      setError,
      t,
      updateUploadedFilesSet,
      uploadFiles,
    ]
  );

  useEffect(() => {
    if (error) {
      setTimeout(() => cleanError(), 10000);
    }
  }, [error, cleanError]);

  return (
    <>
      {question.parentQuestion ? (
        <MiniFileUpload
          name={elementName}
          onUploadFiles={handleUploadFiles}
          acceptedTypes={acceptedTypes}
          allowMultiple={allowMultiple}
          files={files}
          isUploading={isUploading}
          isRemoving={isRemoving}
          onRemoveFile={handleRemoveFile}
          onDownloadFile={downloadFile}
          error={error}
          disabled={isReadOnly}
          onCleanError={cleanError}
        />
      ) : (
        <FileUpload
          name={elementName}
          onUploadFiles={handleUploadFiles}
          acceptedTypes={acceptedTypes}
          allowMultiple={allowMultiple}
          files={files}
          isUploading={isUploading}
          isRemoving={isRemoving}
          onRemoveFile={handleRemoveFile}
          onDownloadFile={downloadFile}
          error={error}
          disabled={isReadOnly}
          onCleanError={cleanError}
        />
      )}
    </>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-file-question",
  function (props: any) {
    return createElement(FileQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "file",
  "file-question",
  "sv-file-question"
);
