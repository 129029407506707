export function Logo(): JSX.Element {
  return (
    <svg
      width="120"
      height="24"
      viewBox="0 0 120 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_907_1541)">
        <g clipPath="url(#clip1_907_1541)">
          <path
            d="M7.57156 16.2827L9.99838 0.153625H14.9485V22.871H11.578V6.58834L9.11743 22.871H5.74685L3.10553 6.81261V22.871H0V0.162842H4.95016L7.57156 16.2827Z"
            fill="#1A2023"
          />
          <path
            d="M29.0545 22.871H25.4633L24.8505 18.7512H20.4795L19.8666 22.871H16.5972L20.2236 0.162842H25.4327L29.0545 22.871ZM20.9345 15.6682H24.3602L22.6443 4.18435L20.9345 15.6682Z"
            fill="#1A2023"
          />
          <path
            d="M30.7046 0.162842H36.3335C39.894 0.162842 41.6406 2.14134 41.6406 5.77421V17.2581C41.6406 20.8909 39.894 22.871 36.3335 22.871H30.7046V0.162842ZM34.2636 3.40554V19.6267H36.2691C37.4013 19.6267 38.0816 19.043 38.0816 17.4209V5.61292C38.0816 3.9908 37.4013 3.40554 36.2691 3.40554H34.2636Z"
            fill="#1A2023"
          />
          <path
            d="M47.4638 9.73273H52.3496V12.9754H47.4638V19.6267H53.6105V22.871H43.9048V0.162842H53.6105V3.40554H47.4638V9.73273Z"
            fill="#1A2023"
          />
          <path
            d="M65.2667 5.80645C65.2667 12.1321 56.6595 14.2412 56.5338 22.0599V22.1889H65.1288V22.871H55.8107V22.384C55.8107 13.8848 64.5436 11.9386 64.5436 5.83871C64.5436 2.98464 63.6381 0.648233 60.4989 0.648233C57.4577 0.648233 56.4879 3.11367 56.4879 5.83871V7.06759H55.8076V5.87097C55.8076 2.72504 57.0333 0 60.531 0C64.0947 0 65.2667 2.59447 65.2667 5.80645Z"
            fill="#1A2023"
          />
          <path
            d="M74.8359 11.5807V12.2627H68.4931V22.871H67.7822V0.162842H76.0968V0.843333H68.4931V11.5807H74.8359Z"
            fill="#1A2023"
          />
          <path
            d="M78.5562 22.871V0.162842H79.2686V22.189H86.8692V22.871H78.5562Z"
            fill="#1A2023"
          />
          <path
            d="M89.2974 5.74193C89.2974 2.56221 90.6563 0 94.0867 0C97.5798 0 98.9388 2.56221 98.9388 5.74193V17.2903C98.9388 20.47 97.5798 23.0323 94.0867 23.0323C90.6563 23.0323 89.2974 20.47 89.2974 17.2903V5.74193ZM90.0098 17.3226C90.0098 20.1137 91.0822 22.3917 94.0867 22.3917C97.1278 22.3917 98.2233 20.1213 98.2233 17.3303V5.70968C98.2233 2.92012 97.1232 0.648233 94.0867 0.648233C91.0761 0.648233 90.0098 2.92012 90.0098 5.71736V17.3226Z"
            fill="#1A2023"
          />
          <path
            d="M106.511 22.871H105.475L101.398 0.162842H102.11L106.057 21.8971L109.973 0.291874H110.846L114.534 21.7681L118.352 0.162842H119L114.988 22.871H114.048L110.393 1.84333L106.511 22.871Z"
            fill="#1A2023"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_907_1541">
          <rect width="120" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_907_1541">
          <rect width="119" height="23.0323" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
