import { IconProps } from "react-feather";

export function Wood({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        d="M15.1786 17.9286C15.3585 17.9286 15.5311 17.8571 15.6584 17.7298C15.7857 17.6026 15.8571 17.43 15.8571 17.25V11.8215C15.8571 11.6415 15.7857 11.4689 15.6584 11.3416C15.5311 11.2144 15.3585 11.1429 15.1786 11.1429C14.9986 11.1429 14.826 11.2144 14.6987 11.3416C14.5715 11.4689 14.5 11.6415 14.5 11.8215V17.25C14.5 17.43 14.5715 17.6026 14.6987 17.7298C14.826 17.8571 14.9986 17.9286 15.1786 17.9286Z"
        fill={color}
      />
      <path
        d="M11.7857 3C7.91786 3 5 4.45825 5 6.39286V18.6071C5 20.5417 7.91786 22 11.7857 22C15.6536 22 18.5714 20.5417 18.5714 18.6071V6.39286C18.5714 4.45825 15.6536 3 11.7857 3ZM17.2143 18.6071C17.2143 19.3787 15.3821 20.4739 12.4643 20.6239V16.5714C12.4643 16.3915 12.3928 16.2189 12.2655 16.0916C12.1383 15.9643 11.9657 15.8929 11.7857 15.8929C11.6057 15.8929 11.4331 15.9643 11.3059 16.0916C11.1786 16.2189 11.1071 16.3915 11.1071 16.5714V20.6239C8.18929 20.4739 6.35714 19.3787 6.35714 18.6071V8.472C6.78219 8.74468 7.23766 8.96673 7.71429 9.13361V14.5357C7.71429 14.7157 7.78578 14.8883 7.91303 15.0155C8.04029 15.1428 8.21289 15.2143 8.39286 15.2143C8.57283 15.2143 8.74542 15.1428 8.87268 15.0155C8.99994 14.8883 9.07143 14.7157 9.07143 14.5357V9.51429C9.96453 9.69731 10.8741 9.78827 11.7857 9.78571C13.6826 9.86507 15.5636 9.40987 17.2143 8.472V18.6071ZM11.7857 8.42857C8.47157 8.42857 6.35714 7.22275 6.35714 6.39286C6.35714 5.56296 8.47157 4.35714 11.7857 4.35714C15.0999 4.35714 17.2143 5.56296 17.2143 6.39286C17.2143 7.22275 15.0999 8.42857 11.7857 8.42857Z"
        fill={color}
      />
    </svg>
  );
}
