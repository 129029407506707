import {
  PageModel,
  Question,
  QuestionExpressionModel,
  QuestionFileModel,
  QuestionMatrixDynamicModel,
  QuestionPanelDynamicModel,
  SurveyModel,
} from "survey-core";
import { IconName } from "../types/icon-type";
import {
  isExpression,
  prepareBooleanAsChecbkoxQuestion,
  isRadioGroup,
  isMatrixDynamic,
  isMatrixDropdown,
  isDynamicPanel,
  isFile,
  isBoolean,
} from ".";

/**
 * A Map of old pages that do not support the new page renderer.
 */
const oldPagesMap = new Map<
  string,
  { titleElement: string; iconName: IconName }
>([
  [
    "general-information-page",
    {
      titleElement: "gi-page-title",
      iconName: "Facility",
    },
  ],
  [
    "production-and-processes-page",
    {
      titleElement: "pp-page-title",
      iconName: "Process",
    },
  ],
  [
    "production-and-processes-page-2",
    {
      titleElement: "pp-page-title-2",
      iconName: "Process",
    },
  ],
  [
    "energy-and-utility-page",
    {
      titleElement: "ee-page-title",
      iconName: "Energy",
    },
  ],
  [
    "energy-and-utility-page-2",
    {
      titleElement: "ee-page-title-2",
      iconName: "Energy",
    },
  ],
  [
    "water-page",
    {
      titleElement: "w-page-title",
      iconName: "Water",
    },
  ],
  [
    "water-page-2",
    {
      titleElement: "w-page-title-2",
      iconName: "Water",
    },
  ],
  [
    "ww-page",
    {
      titleElement: "ww-page-title",
      iconName: "WasteWater",
    },
  ],
  [
    "dry-waste-page",
    {
      titleElement: "dw-page-title",
      iconName: "DryWaste",
    },
  ],
]);

/**
 *
 * Add an icon to the page title element and update the renderAs prop.
 */
function prepareOldPageTitle(page: PageModel) {
  const oldPage = oldPagesMap.get(page.name);
  if (oldPage) {
    const pageQuestions = page.getQuestions(false);
    const titleElement = pageQuestions.find((q) =>
      q.name.includes(oldPage.titleElement)
    ) as QuestionExpressionModel;

    if (titleElement) {
      titleElement.setPropertyValue("icon", oldPage.iconName);
      titleElement.renderAs = "page-title-expression";
    }
  }
}

function addValidators(question: Question) {
  const name = question.getValueName() ?? question.name;
  if (question.name.includes("pq-apparel-product-details-matrix-dynamic")) {
    question.setPropertyValue("validators", {
      type: "expression",
      text: "%-%tolgee:bpq-apparel-product-has-main-fabric-validation-message%-%",
      expression: `validateProductHasMainFabric({${name}})`,
    });
  }
}

export function prepareSurvey(survey: SurveyModel) {
  survey.pages.forEach((page) => {
    prepareOldPageTitle(page);
    page.questions.forEach(addValidators);
  });

  /**
   * Some props (such as validators, for example) cannot be changed after loading the Survey object with a JSON schema.
   * To force the props update, We do a little trick of reloading the json to the Survey object.
   */
  survey.fromJSON(survey.toJSON());
}

export function prepareQuestions(questions: Question[]) {
  questions.forEach((q) => {
    if (isExpression(q)) {
      if (q.page.name.includes("intro-page") && q.name.includes("intro-page")) {
        q.renderAs = "intro-page-title";
      } else {
        if (q.name.includes("client-data")) q.page.removeElement(q);
      }
    } else if (isBoolean(q) && q.renderAs !== "boolean-as-checkbox-question") {
      if (q.renderAs === "checkbox") {
        prepareBooleanAsChecbkoxQuestion(q);
      } else {
        q.renderAs = "boolean-question";
      }
    } else if (isRadioGroup(q)) {
      q.renderAs = "radio-group-question";
    } else if (isMatrixDynamic(q) || isMatrixDropdown(q)) {
      prepareMatrixQuestion(q as any);
    } else if (isDynamicPanel(q)) {
      prepareDynamicPanelQuestion(q as QuestionPanelDynamicModel);
    } else if (isFile(q)) {
      renderFileQuestionsAs(q);
    }
  });
}

const prepareMatrixQuestion = (
  question: QuestionMatrixDynamicModel | QuestionMatrixDynamicModel
) => {
  if (
    question.page.name.includes("energy-and-utility") ||
    question.page.name.includes("water") ||
    question.page.name.includes("ww") ||
    question.page.name.includes("dry-waste")
  )
    question.columns.forEach((column) => {
      if (column.cellType === "file") {
        column.renderAs = "file-question";
        column.allowMultiple = true;
        column.minWidth = "280px";
        column.width = "280px";
      } else if (column.name.includes("amount")) {
        column.width = "150px";
        column.minWidth = "150px";
      } else if (column.name.includes("comment")) {
        column.minWidth = "190px";
      } else if (column.name.includes("unit")) {
        column.width = "150px";
        column.minWidth = "150px";
      } else if (
        column.name.includes("tracking") ||
        column.name.includes("trackiong")
      ) {
        removePlceholderFromTrackingQInMatrix(column);
        column.width = "160px";
        column.minWidth = "160px";
      }
    });
};

const prepareDynamicPanelQuestion = (question: QuestionPanelDynamicModel) => {
  if (
    question.page.name.includes("energy-and-utility") ||
    question.page.name.includes("water") ||
    question.page.name.includes("ww") ||
    question.page.name.includes("dry-waste")
  ) {
    question.templateElements.forEach((elem) => {
      if (
        elem.getType() === "matrixdropdown" ||
        elem.getType() === "matrixdynamic"
      ) {
        prepareMatrixQuestion(elem as any);
      } else if (elem.getType() === "file") {
        (elem as QuestionFileModel).renderAs = "file-question";
        (elem as QuestionFileModel).allowMultiple = true;
      }
    });
  }
};

const renderFileQuestionsAs = (question: Question) => {
  question.renderAs = "file-question";
  question.allowMultiple = true;
  if (question.parent.name.includes("mecinery-section")) {
    question.maxWidth = "70%";
  }
};

//remove Placeholder from tracking method questions inside a matrix
function removePlceholderFromTrackingQInMatrix(question: Question) {
  question.placeholder = undefined;
}
