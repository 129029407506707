import { T } from "@tolgee/react";
import classNames from "classnames";
import { ButtonHTMLAttributes, FC } from "react";
import { ChevronRight } from "../icons";

export const NextButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        "bg-indigo-500 md:py-2.5 md:px-4 p-2 rounded-lg",
        className
      )}
    >
      <div className="flex items-center gap-2">
        <span className="hidden text-sm font-semibold leading-5 text-white md:inline">
          <T keyName="button-next" />
        </span>
        <ChevronRight size={20} color="white" />
      </div>
    </button>
  );
};
