import { useAtomValue } from "jotai";
import * as Dialog from "@radix-ui/react-dialog";
import { T, useTolgee } from "@tolgee/react";
import { explainerContentQuestionAtom } from "../../../../jotai-atoms";
import { ExplainerContent } from "../explainer-content";
import * as Icon from "../../../icons";
import { useMemo } from "react";
import { ExplainerExpression } from "../../../../types";
import { isExplainerImage } from "../../../../utils";
import { useBreakpoint } from "../../../../hooks";

export const ExplainerDialog = () => {
  const tolgee = useTolgee();
  const { isAboveMd } = useBreakpoint("md");
  const explainerExpression = useAtomValue(explainerContentQuestionAtom);
  const data = explainerExpression?.expression as ExplainerExpression;

  const explainerImage = useMemo(() => {
    if (data.explainerImage) {
      if (typeof data.explainerImage === "string") {
        return {
          src: `images/explainers/${tolgee.getLanguage()}/${
            data.explainerImage
          }`,
        };
      } else if (isExplainerImage(data.explainerImage)) {
        return {
          src: `images/explainers/${tolgee.getLanguage()}/${
            data.explainerImage.src
          }`,
          size: data.explainerImage.size,
        };
      }
    }
  }, [data.explainerImage, tolgee]);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className="flex items-center gap-2.5 overflow-hidden capitalize rounded-lg outline-none appearance-none select-none bg-mandarin-50 p-2 md:py-2.5 md:px-5">
          <Icon.HelpCircle
            className="shrink-0"
            color="var(--mandarin-600)"
            size={isAboveMd ? 20 : 14}
          />
          <span className="font-medium leading-[18px] md:leading-6 truncate text-xs md:text-md text-mandarin-900">
            <T keyName="explainer-dialog-trigger-button-text" />
          </span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-40 bg-indigo-900 opacity-50" />
        <Dialog.Content className="z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-5 md:p-[30px] shadow-xl">
          <Dialog.DialogTitle></Dialog.DialogTitle>
          <Dialog.Description></Dialog.Description>
          <ExplainerContent
            data={{
              title: explainerExpression?.title,
              points: data.points,
              explainerImage,
            }}
          />
          <div className="flex justify-end mt-10">
            <Dialog.Close asChild>
              <button className="bg-indigo-500 py-2 px-[14px] rounded-lg select-none text-sm font-medium text-white leading-5 appearance-none outline-none">
                <T keyName="explainer-dialog-close-button-text" />
              </button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <button
                aria-label="Close"
                className="absolute top-2.5 right-2.5 md:top-[30px] md:right-[30px] appearance-none outline-none"
              >
                <Icon.X color="var(--gray-400)" />
              </button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
