import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ClearFilesQueryParams,
  IApiService,
  IFetchAnswersData,
  IFetchResourceTypeData,
  IFetchSchemaData,
  IGenerateQuestionnaireIdData,
  ISaveAnswerData,
  ISaveItemsPerPageData,
  ISavePageNumberData,
  ISideBarContentData,
  ResourceType,
} from "../interfaces";
import { appConfig } from "../config";
import { VisibleQuestionItem } from "../utils";

export class ApiService implements IApiService {
  private API_URL = appConfig.surveyApi;
  private _gatherProcessId: string;
  private _questionnaireId: string;
  private _resourceType: ResourceType;
  private _pending: boolean;

  axiosInstance = axios.create({
    baseURL: this.API_URL,
  });

  constructor({
    gatherProcessId,
    questionnaireId,
    resourceType,
  }: {
    gatherProcessId?: string;
    questionnaireId?: string;
    resourceType?: ResourceType;
  }) {
    this._gatherProcessId = gatherProcessId ?? "";
    this._questionnaireId = questionnaireId ?? "";
    this._resourceType = resourceType ?? "facility-questionnaire";
    this._pending = false;
  }

  private ensureIds(url: string) {
    if (url.includes("gatherProcessId") && this._gatherProcessId) {
      url = url.replace("gatherProcessId", this._gatherProcessId);
    }
    if (url.includes("questionnaireId") && this._questionnaireId) {
      url = url.replace("questionnaireId", this._questionnaireId);
    }
    return url;
  }

  private async sendRequestWithRetry<T = any, D = any>(
    config: AxiosRequestConfig<D>,
    retries = 3,
    delay = 1000
  ): Promise<AxiosResponse<T, D>> {
    let attempt = 0;
    while (attempt < retries) {
      try {
        const response: AxiosResponse<T, D> = await this.axiosInstance(config);
        return response;
      } catch (error) {
        if (attempt < retries - 1) {
          await this.delay((attempt + 1) * delay);
          attempt++;
        } else {
          throw error;
        }
      }
    }
    throw new AxiosError("Failed to send request after multiple attempts");
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async sendRequest<T = any, D = any>(config: AxiosRequestConfig<D>) {
    if (!config.url) {
      throw new Error("URL must be provided in the request config");
    }
    config = { ...config, url: this.ensureIds(config.url) };
    return this.sendRequestWithRetry<T, D>(config);
  }

  public async fetchResourceType() {
    let response;
    this.pending = true;
    try {
      response = await this.sendRequest<IFetchResourceTypeData>({
        url: `questionnaire/gatherProcessId/resource-type`,
        method: "get",
      });

      if (!response.data) {
        throw new AxiosError("use-provided-link-error-message");
      } else {
        this.resourceType = response.data.resource;
      }
    } finally {
      this.pending = false;
    }

    return response;
  }

  public async fetchSchema() {
    let response;
    this.pending = true;
    try {
      response = await this.sendRequest<IFetchSchemaData>({
        url: `questionnaire/${this._resourceType}/gatherProcessId/schema`,
        method: "get",
      });

      if (!response.data) {
        throw new AxiosError("use-provided-link-error-message");
      } else {
        if (response.data.questionnaireId) {
          this.questionnaireId = response.data.questionnaireIdquestionnaireId;
        }
      }
    } finally {
      this.pending = false;
    }

    return response;
  }

  public async fetchAnswers() {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "get",
    };
    switch (this._resourceType) {
      case "facility-questionnaire":
        request.url = `questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/answer`;
        break;
      case "product-questionnaire":
        request.url = `questionnaire/product-questionnaire/gatherProcessId/questionnaire/answer`;
        break;
    }
    try {
      response = await this.sendRequest<IFetchAnswersData>(request);

      if (!response.data) {
        throw new AxiosError("use-provided-link-error-message");
      }
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async saveAnswer(name: string, value: any) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "post",
    };
    switch (this._resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/answer/${name}`;
        request.data = { value };
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/answer`;
        request.data = {
          answerKey: name,
          value,
        };
        break;
    }
    try {
      response = await this.sendRequest<ISaveAnswerData>(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async createQuestionnaire(data: any) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "post",
      data,
    };
    switch (this._resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire`;
        break;
      case "product-questionnaire":
        request.url =
          "/questionnaire/product-questionnaire/gatherProcessId/questionnaire";
        break;
    }

    try {
      response = await this.sendRequest<IGenerateQuestionnaireIdData>(request);

      if (!response.data) {
        throw new AxiosError("use-provided-link-error-message");
      } else {
        this.questionnaireId = response.data.questionnaireId;
      }
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async savePageNumber(pageNumber: number) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "patch",
      data: { pageNumber },
    };

    switch (this._resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/answer/page-number`;
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/page-number`;
    }
    try {
      response = await this.sendRequest<ISavePageNumberData>(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async completeQuestionnaire(
    visibleQuestions: Array<VisibleQuestionItem>,
    currentData: Record<string, any>
  ) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "patch",
    };

    switch (this._resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/complete`;
        request.data = { visibleQuestions, currentData };
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/send-to-review`;
        request.data = { visibleQuestions: [] };
    }

    try {
      response = await this.sendRequest(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async saveItemsPerPage(itemsPerPage: number) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "patch",
      data: { itemsPerPage },
    };

    switch (this.resourceType) {
      case "facility-questionnaire":
        request.url = "";
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/questionnaireId/items-per-page`;
    }

    try {
      response = await this.sendRequest<ISaveItemsPerPageData>(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async uploadFile(answerKey: string, data: FormData) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "post",
      data,
    };

    switch (this.resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/answer/${answerKey}/upload-file`;
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/answer/${answerKey}/file/upload`;
    }
    try {
      response = await this.sendRequest(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async removeFile(fileId: string) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "delete",
    };

    switch (this.resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/file/${fileId}`;
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/file/${fileId}`;
    }
    try {
      response = await this.sendRequest(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async getFileDownloadUrl(fileId: string) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "get",
    };

    switch (this.resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/file/${fileId}/download/url`;
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/file/${fileId}/download/url`;
    }
    try {
      response = await this.sendRequest(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async clearFiles(name: string, params: ClearFilesQueryParams = {}) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "delete",
    };

    switch (this.resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/answer/${name}/file/clear${this.buildQueryParams(
          { ...params }
        )}`;
        break;
      case "product-questionnaire":
        request.url = "";
    }
    try {
      response = await this.sendRequest(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async removeGroupByIndex(answerKey: string, groupIndex: number) {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "delete",
    };

    switch (this._resourceType) {
      case "facility-questionnaire":
        request.url = `/questionnaire/facility-questionnaire/gatherProcessId/questionnaire/questionnaireId/answer/${answerKey}/group${this.buildQueryParams(
          { groupIndex }
        )}`;
        break;
      case "product-questionnaire":
        request.url = `/questionnaire/product-questionnaire/gatherProcessId/questionnaire/answer/${answerKey}/group${this.buildQueryParams(
          {
            groupIndex,
          }
        )}`;
        break;
    }
    try {
      response = await this.sendRequest(request);
    } finally {
      this.pending = false;
    }
    return response;
  }

  public async getSideBarContent() {
    let response;
    this.pending = true;
    const request: AxiosRequestConfig = {
      method: "get",
      url: `/questionnaire/${this._resourceType}/gatherProcessId/sidebar`,
    };
    try {
      response = await this.sendRequest<ISideBarContentData>(request);
      return response;
    } finally {
      this.pending = false;
    }
  }

  public set gatherProcessId(gatherProcessId: string) {
    this._gatherProcessId = gatherProcessId;
  }

  public get gatherProcessId() {
    return this._gatherProcessId;
  }

  public get questionnaireId() {
    return this._questionnaireId;
  }

  public set questionnaireId(questionnaireId: string) {
    this._questionnaireId = questionnaireId;
  }

  public get resourceType() {
    return this._resourceType;
  }

  public set resourceType(
    resourceType: "facility-questionnaire" | "product-questionnaire"
  ) {
    this._resourceType = resourceType;
  }

  public get pending() {
    return this._pending;
  }

  protected set pending(pending: boolean) {
    this._pending = pending;
  }

  private buildQueryParams(possibleValues: Record<string, unknown>) {
    if (!possibleValues) {
      return "";
    }

    const paramNames = Object.keys(possibleValues);
    const possibleParams = paramNames
      .map((paramName) => {
        const paramValue = possibleValues[paramName];

        if (paramValue === undefined || paramValue === null) {
          return "";
        }

        return `${paramName}=${paramValue}`;
      })
      .filter(Boolean) as Array<string>;

    if (!possibleParams.length) {
      return "";
    }

    return `?${possibleParams.join("&")}`;
  }
}
