import { T } from "@tolgee/react";
import { ChevronRight } from "../icons";
import { FC } from "react";

interface StartButtonProps {
  onClick: () => void;
  label: string;
}

export const StartButton: FC<StartButtonProps> = ({ onClick, label }) => {
  return (
    <div className="flex flex-row-reverse w-full">
      <button
        onClick={onClick}
        className="bg-indigo-500 py-2.5 px-4 rounded-lg md:w-fit w-full"
      >
        <div className="flex items-center justify-center gap-2">
          <span className="text-sm font-semibold text-white">
            <T keyName={label} />
          </span>
          <ChevronRight size={20} color="white" />
        </div>
      </button>
    </div>
  );
};
