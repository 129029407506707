import { T } from "@tolgee/react";
import { FC, useEffect } from "react";
import { X } from "react-feather";
import { toast, ToastContainer, ToastContainerProps } from "react-toastify";

type MobileToastContainerProps = {} & Pick<ToastContainerProps, "style">;

export const MobileToastContainer: FC<MobileToastContainerProps> = ({
  style,
}) => {
  useEffect(() => {
    const renderToast = () => {
      toast(
        <div className="text-xs leading-[18px]">
          <div className="font-medium text-mandarin-600">
            <T keyName="mobile-only-warning-title" />
          </div>
          <div className=" font-regular text-mandarin-900">
            <T keyName="mobile-only-warning-message" />
          </div>
        </div>,
        {
          toastId: "better-on-desktop-message",
          containerId: "mobile-only",
          autoClose: false,
        }
      );
    };
    renderToast();
  }, []);

  return (
    <ToastContainer
      toastClassName="bg-mandarin-25 shadow-md p-5 md:hidden"
      style={{
        zIndex: 40,
        ...style,
      }}
      closeButton={(props) => (
        <button
          aria-label={props.ariaLabel}
          className="h-fit"
          type="button"
          onClick={props.closeToast}
        >
          <X size={20} color="var(--mandarin-600" />
        </button>
      )}
      containerId="mobile-only"
      position="top-center"
    />
  );
};
