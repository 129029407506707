import { Serializer } from "survey-core";
import { IconNames } from "./types/icon-type";

// survey props
Serializer.addProperty("survey", {
  name: "navigationMenu",
  category: "general",
  choices: ["none", "sidebar", "pagination"],
  default: "sidebar",
});

Serializer.addProperty("survey", {
  name: "sidebarItems",
  category: "general",
});

Serializer.addProperty("survey", {
  name: "totalProducts",
  category: "general",
  type: "number",
  default: 0,
});

Serializer.addProperty("survey", {
  name: "questionnaireId",
  category: "general",
  type: "string",
  default: "",
});

//page props
Serializer.addProperty("page", {
  name: "withHelper",
  type: "boolean",
  default: false,
  category: "general",
});

//question props
Serializer.addProperty("question", {
  name: "icon",
  displayName: "Question Icon",
  choices: ["", ...IconNames],
  category: "general",
});

Serializer.addProperty("question", {
  name: "iconColor",
  displayName: "Question Icon Color",
  default: "gray-500",
  category: "general", //The best would be to find a way to create an array from all the different tailwind color classes and put it in "choices".
});
